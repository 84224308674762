import React from "react";
import { Link } from "react-router-dom";
import api from "../servicios/api";
import Swal from "sweetalert2";
import "../styles/style.css";

const capitalizarCadaPalabra = (texto) => {
  return texto
    .split(" ")
    .map(
      (palabra) =>
        palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
    )
    .join(" ");
};

const ITEMS_POR_PAGINA = 10;
const RANGO_PAGINAS = 10;

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      pacientes: [],
      textoBusqueda: "",
      paginaActual: 1,
      nombreUsuario: localStorage.getItem("nombre") || "",
      tipoUsuario: localStorage.getItem("tipo") || "",
    };
  }

  borrarRegistros = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(api + "?borrar=" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            // console.log(datosRespuesta);
            this.cargarDatos();
            Swal.fire("¡Borrado!", "El registro ha sido borrado.", "success");
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "Hubo un problema al borrar el registro.",
              "error"
            );
          });
      }
    });
  };

  cargarDatos() {
    fetch(api)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        // console.log(datosRespuesta);
        this.setState({ datosCargados: true, pacientes: datosRespuesta });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  manejarCambioBusqueda = (evento) => {
    this.setState({
      textoBusqueda: evento.target.value,
      paginaActual: 1, // Restablecer a la primera página cuando cambie la búsqueda
    });
  };

  limpiarBusqueda = () => {
    this.setState({
      textoBusqueda: "",
      paginaActual: 1, // Restablecer a la primera página
    });
  };

  obtenerPacientesFiltrados() {
    const { pacientes, textoBusqueda, tipoUsuario, nombreUsuario } = this.state;

    // Convertir texto de búsqueda a minúsculas para comparaciones
    const textoBusquedaLower = textoBusqueda.toLowerCase();

    // Filtrar pacientes por nombre, cédula o doctor, asegurándose de que los campos existan
    let pacientesFiltrados = pacientes.filter(
      (paciente) =>
        paciente.nombre?.toLowerCase().includes(textoBusquedaLower) ||
        "" ||
        paciente.identificacion?.toLowerCase().includes(textoBusquedaLower) ||
        "" ||
        paciente.nombreUsuario?.toLowerCase().includes(textoBusquedaLower) ||
        ""
    );

    // Filtrar pacientes según el tipo de usuario
    if (tipoUsuario === "doctor") {
      pacientesFiltrados = pacientesFiltrados.filter(
        (paciente) => paciente.nombreUsuario === nombreUsuario
      );
    }

    // Ordenar pacientes por fecha (asumiendo que hay un campo de fecha en los pacientes)
    function parseDateString(dateString) {
      const [day, month, year] = dateString.split("/");
      return new Date(`${year}-${month}-${day}`);
    }

    return pacientesFiltrados.sort(
      (a, b) => parseDateString(b.fechaActual) - parseDateString(a.fechaActual)
    );
  }
  manejarCambioPagina = (pagina) => {
    this.setState({ paginaActual: pagina });
  };

  manejarCambioAsistio = (id, valorAsistio) => {
    fetch(api + "?actualizarAsistio=" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ asistio: valorAsistio ? 1 : 0 }),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success) {
          Swal.fire(
            "Actualizado",
            "El estado de asistencia ha sido actualizado.",
            "success"
          );

          // Actualizar el estado del paciente en el estado del componente
          this.setState((prevState) => {
            const pacientesActualizados = prevState.pacientes.map(
              (paciente) => {
                if (paciente.id === id) {
                  return { ...paciente, asistio: valorAsistio ? 1 : 0 };
                }
                return paciente;
              }
            );
            return { pacientes: pacientesActualizados };
          });
        } else {
          Swal.fire(
            "Error",
            "Hubo un problema al actualizar el estado de asistencia.",
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire(
          "Error",
          "Hubo un problema al conectar con el servidor.",
          "error"
        );
      });
  };

  render() {
    const { datosCargados, textoBusqueda, paginaActual } = this.state;
    const pacientesFiltrados = this.obtenerPacientesFiltrados();
    const tipoUsuario = localStorage.getItem("tipo");

    // Calcular el índice inicial y final para la paginación
    const indiceInicial = (paginaActual - 1) * ITEMS_POR_PAGINA;
    const pacientesPaginaActual = pacientesFiltrados.slice(
      indiceInicial,
      indiceInicial + ITEMS_POR_PAGINA
    );

    // Número total de páginas
    const totalPaginas = Math.ceil(
      pacientesFiltrados.length / ITEMS_POR_PAGINA
    );

    // Calcular el rango de botones de página a mostrar
    const paginaInicio = Math.max(
      1,
      paginaActual - Math.floor(RANGO_PAGINAS / 2)
    );
    const paginaFin = Math.min(totalPaginas, paginaInicio + RANGO_PAGINAS - 1);

    if (!datosCargados) {
      return <div>Cargando...</div>;
    }

    return (
      <div className="card poppins-regular">
        <div className="card-header bg-dark">
          <div className="d-flex justify-content-between align-items-center">
            <Link className="btn btn-light" to={"/crear"}>
              <i className="fas fa-user-plus"></i> Agregar Nuevo Paciente
            </Link>

            <div className="position-relative w-50">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar paciente por nombre, identificación o doctor"
                value={textoBusqueda}
                onChange={this.manejarCambioBusqueda}
              />
              {textoBusqueda && (
                <button
                  type="button"
                  className="btn btn-light position-absolute top-50 end-0 translate-middle-y me-2"
                  onClick={this.limpiarBusqueda}
                  style={{ border: "none", boxShadow: "none" }}
                >
                  <i className="fas fa-times text-danger"></i>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body text-center text-white bg-success">
          <h2 className="fw-bold pb-2">LISTA DE PACIENTES</h2>
          <table className="table align-middle">
            <thead className="table-dark">
              <tr>
                <th>N°</th>
                <th>NOMBRE Y APELLIDO</th>
                <th>IDENTIFICACIÓN</th>
                <th>DOCTOR(A)</th>
                <th>FECHA</th>
                <th>ASISTIÓ</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              {pacientesPaginaActual.map((paciente, index) => (
                <tr key={paciente.id}>
                  <td className="fw-bold">{indiceInicial + index + 1}</td>
                  <td className="text-truncate" style={{ maxWidth: "200px" }}>
                    {capitalizarCadaPalabra(paciente.nombre)}
                  </td>
                  <td className="text-truncate" style={{ maxWidth: "50px" }}>
                    {paciente.identificacion}
                  </td>
                  <td>{paciente.nombreUsuario}</td>
                  <td>{paciente.fechaActual}</td>
                  <td>
                    <fieldset disabled={tipoUsuario !== "administrador"}>
                      <div className="form-check form-switch d-flex justify-content-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={parseInt(paciente.asistio) === 1}
                          onChange={(e) =>
                            this.manejarCambioAsistio(
                              paciente.id,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </fieldset>
                  </td>

                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <Link
                        className="btn btn-primary"
                        to={"/ver/" + paciente.id}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </Link>
                      <Link
                        className="btn btn-warning"
                        to={"/editar/" + paciente.id}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </Link>
                      {tipoUsuario === "administrador" && (
                        <button
                          className="btn btn-danger"
                          onClick={() => this.borrarRegistros(paciente.id)}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer text-muted bg-dark">
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link text-success"
                  disabled={paginaActual === 1}
                  onClick={() => this.manejarCambioPagina(1)}
                >
                  Primera
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link text-success"
                  disabled={paginaActual === 1}
                  onClick={() => this.manejarCambioPagina(paginaActual - 1)}
                >
                  Anterior
                </button>
              </li>
              {Array.from({ length: paginaFin - paginaInicio + 1 }, (_, i) => (
                <li
                  key={paginaInicio + i}
                  className={`page-item ${
                    paginaActual === paginaInicio + i
                      ? "bg-success text-white"
                      : ""
                  }`}
                >
                  <button
                    className={`page-link ${
                      paginaActual === paginaInicio + i
                        ? "text-white bg-success"
                        : "text-success"
                    }`}
                    style={{
                      backgroundColor:
                        paginaActual === paginaInicio + i ? "#ffffff" : "",
                    }}
                    onClick={() => this.manejarCambioPagina(paginaInicio + i)}
                  >
                    {paginaInicio + i}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  className="page-link text-success"
                  disabled={paginaActual === totalPaginas}
                  onClick={() => this.manejarCambioPagina(paginaActual + 1)}
                >
                  Siguiente
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link text-success"
                  disabled={paginaActual === totalPaginas}
                  onClick={() => this.manejarCambioPagina(totalPaginas)}
                >
                  Última
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Listar;
