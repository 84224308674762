import "./App.css";
import "./styles/style.css";
import Listar from "./componentes/listar";
import Crear from "./componentes/crear";
import Editar from "./componentes/editar";
import Login from "./componentes/login";
import Ver from "./componentes/ver";
import AgregarDoctor from "./componentes/agregarDoctor";
import ListaDoctores from "./componentes/listaDoctores"; // Asegúrate de importar el componente correcto
import EditarDoctor from "./componentes/editarDoctor";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useState } from "react";

// Componente que maneja el Navbar y el Logout
const Navbar = () => {
  const history = useHistory();
  const sesionActiva = localStorage.getItem("sesionActiva");
  const nombreUsuario = localStorage.getItem("nombre");
  const tipoUsuario = localStorage.getItem("tipo");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("sesionActiva");
    localStorage.removeItem("nombre");
    localStorage.removeItem("tipo");
    history.push("/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-success d-flex justify-content-between align-items-center">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <Link
            className="nav-item nav-link active text-white fw-bold"
            to="/listar"
          >
            <img src="/logo-blanco.png" alt="" height="35" className="ms-4" />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <Link
            className="nav-item nav-link text-white nav-icon mx-4"
            to="/listar"
          >
            <i className="fa-solid fa-house"></i> INICIO
          </Link>
          <Link
            className="nav-item nav-link text-white nav-icon mx-2"
            to="/crear"
          >
            <i className="fas fa-user-plus"></i> AGREGAR PACIENTE
          </Link>
          {tipoUsuario !== "doctor" && (
            <Link
              className="nav-item nav-link text-white nav-icon mx-2"
              to="/agregar-doctor"
            >
              <i className="fa-solid fa-stethoscope"></i> AGREGAR DOCTOR
            </Link>
          )}
          {tipoUsuario !== "doctor" && (
            <Link
              className="nav-item nav-link text-white nav-icon mx-2"
              to="/lista-doctores"
            >
              <i className="fa-solid fa-list"></i> LISTA DOCTORES
            </Link>
          )}
        </div>
        <div className="d-flex align-items-center">
          {sesionActiva && (
            <div className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle text-white"
                onClick={toggleDropdown}
                role="button"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
              >
                <i className="fas fa-user me-2"></i>
                <span className="text-uppercase">DR. {nombreUsuario}</span>
              </div>
              {dropdownOpen && (
                <div className="dropdown-menu dropdown-menu-end show">
                  <button onClick={handleLogout} className="dropdown-item">
                    <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

function App() {
  const sesionActiva = localStorage.getItem("sesionActiva");
  const tipoUsuario = localStorage.getItem("tipo");

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <>
          {sesionActiva && <Navbar />}
          <div className="container-fluid">
            <br />
            <Switch>
              <Route
                path="/listar"
                render={() =>
                  sesionActiva ? <Listar /> : <Redirect to="/login" />
                }
              />
              <Route
                path="/crear"
                render={() =>
                  sesionActiva ? <Crear /> : <Redirect to="/login" />
                }
              />
              <Route
                path="/editar/:id"
                render={() =>
                  sesionActiva ? <Editar /> : <Redirect to="/login" />
                }
              />
              <Route
                path="/agregar-doctor"
                render={() =>
                  sesionActiva && tipoUsuario !== "doctor" ? (
                    <AgregarDoctor />
                  ) : (
                    <Redirect to="/listar" />
                  )
                }
              />
              <Route
                path="/lista-doctores"
                render={
                  () =>
                    sesionActiva && tipoUsuario !== "doctor" ? (
                      <ListaDoctores />
                    ) : (
                      <Redirect to="/listar" />
                    ) // Cambié Crear por ListaDoctores
                }
              />
              <Route
                path="/editar-doctor/:id"
                render={() =>
                  sesionActiva && tipoUsuario !== "doctor" ? (
                    <EditarDoctor />
                  ) : (
                    <Redirect to="/editar-doctor" />
                  )
                }
              />

              <Route
                path="/ver/:id"
                render={() => (sesionActiva ? <Ver /> : <Redirect to="/ver" />)}
              />
              <Redirect from="/" to={sesionActiva ? "/listar" : "/login"} />
            </Switch>
          </div>
        </>
      </Switch>
    </Router>
  );
}

export default App;
