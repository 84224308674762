import React from "react";
import { withRouter } from "react-router-dom";
import api from "../servicios/api";
import Swal from "sweetalert2";

class Ver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      paciente: {
        observacionAdministrador: "",
        observacionDoctor: "",
      },
    };
  }

  cambioValor = (e) => {
    const { name, value, type, checked } = e.target;
    const valueToUse = type === "checkbox" ? checked : value;
    this.setState((prevState) => ({
      paciente: {
        ...prevState.paciente,
        [name]: valueToUse,
      },
    }));
  };

  enviarDatos = (e) => {
    e.preventDefault();
  };

  confirmarCancelar = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Volverás al inicio",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, volver",
      // cancelButtonText: "No, continuar editando",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.history.push("/");
      }
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const tipoUsuario = localStorage.getItem("tipo") || "";

    fetch(api + "?consultar=" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        const paciente = datosRespuesta[0];
        // Convertir valores 0 y 1 a booleanos
        const pacienteConBooleans = {
          ...paciente,
          tipoUsuario: tipoUsuario, // Asigna el tipo de usuario

          oclusionFrente: paciente.oclusionFrente === "1",
          oclusionIzquierda: paciente.oclusionIzquierda === "1",
          oclusionDerecha: paciente.oclusionDerecha === "1",
          arcoSuperior: paciente.arcoSuperior === "1",
          arcoInferior: paciente.arcoInferior === "1",
          overJet: paciente.overJet === "1",
          overBite: paciente.overBite === "1",
          steiner: paciente.steiner === "1",
          mcnamara: paciente.mcnamara === "1",
          ricketts: paciente.ricketts === "1",
          grummons: paciente.grummons === "1",
          // otroDiagnostico: paciente.otroDiagnostico === "1",
          izqArriba1: paciente.izqArriba1 === "1",
          izqArriba2: paciente.izqArriba2 === "1",
          izqArriba3: paciente.izqArriba3 === "1",
          izqArriba4: paciente.izqArriba4 === "1",
          izqArriba5: paciente.izqArriba5 === "1",
          izqArriba6: paciente.izqArriba6 === "1",
          izqArriba7: paciente.izqArriba7 === "1",
          izqArriba8: paciente.izqArriba8 === "1",
          izqAbajo1: paciente.izqAbajo1 === "1",
          izqAbajo2: paciente.izqAbajo2 === "1",
          izqAbajo3: paciente.izqAbajo3 === "1",
          izqAbajo4: paciente.izqAbajo4 === "1",
          izqAbajo5: paciente.izqAbajo5 === "1",
          izqAbajo6: paciente.izqAbajo6 === "1",
          izqAbajo7: paciente.izqAbajo7 === "1",
          izqAbajo8: paciente.izqAbajo8 === "1",
          derArriba1: paciente.derArriba1 === "1",
          derArriba2: paciente.derArriba2 === "1",
          derArriba3: paciente.derArriba3 === "1",
          derArriba4: paciente.derArriba4 === "1",
          derArriba5: paciente.derArriba5 === "1",
          derArriba6: paciente.derArriba6 === "1",
          derArriba7: paciente.derArriba7 === "1",
          derArriba8: paciente.derArriba8 === "1",
          derAbajo1: paciente.derAbajo1 === "1",
          derAbajo2: paciente.derAbajo2 === "1",
          derAbajo3: paciente.derAbajo3 === "1",
          derAbajo4: paciente.derAbajo4 === "1",
          derAbajo5: paciente.derAbajo5 === "1",
          derAbajo6: paciente.derAbajo6 === "1",
          derAbajo7: paciente.derAbajo7 === "1",
          derAbajo8: paciente.derAbajo8 === "1",
          periComp: paciente.periComp === "1",
          parcial: paciente.parcial === "1",
          milimetrada: paciente.milimetrada === "1",
          compCoronales: paciente.compCoronales === "1",
          coronalIzq: paciente.coronalIzq === "1",
          coronalDer: paciente.coronalDer === "1",
          oclusalSup: paciente.oclusalSup === "1",
          oclusalInf: paciente.oclusalInf === "1",
          tomoIzqSup1: paciente.tomoIzqSup1 === "1",
          tomoIzqSup2: paciente.tomoIzqSup2 === "1",
          tomoIzqSup3: paciente.tomoIzqSup3 === "1",
          tomoIzqSup4: paciente.tomoIzqSup4 === "1",
          tomoIzqSup5: paciente.tomoIzqSup5 === "1",
          tomoIzqSup6: paciente.tomoIzqSup6 === "1",
          tomoIzqSup7: paciente.tomoIzqSup7 === "1",
          tomoIzqSup8: paciente.tomoIzqSup8 === "1",
          tomoIzqInf1: paciente.tomoIzqInf1 === "1",
          tomoIzqInf2: paciente.tomoIzqInf2 === "1",
          tomoIzqInf3: paciente.tomoIzqInf3 === "1",
          tomoIzqInf4: paciente.tomoIzqInf4 === "1",
          tomoIzqInf5: paciente.tomoIzqInf5 === "1",
          tomoIzqInf6: paciente.tomoIzqInf6 === "1",
          tomoIzqInf7: paciente.tomoIzqInf7 === "1",
          tomoIzqInf8: paciente.tomoIzqInf8 === "1",
          tomoDerSup1: paciente.tomoDerSup1 === "1",
          tomoDerSup2: paciente.tomoDerSup2 === "1",
          tomoDerSup3: paciente.tomoDerSup3 === "1",
          tomoDerSup4: paciente.tomoDerSup4 === "1",
          tomoDerSup5: paciente.tomoDerSup5 === "1",
          tomoDerSup6: paciente.tomoDerSup6 === "1",
          tomoDerSup7: paciente.tomoDerSup7 === "1",
          tomoDerSup8: paciente.tomoDerSup8 === "1",
          tomoDerInf1: paciente.tomoDerInf1 === "1",
          tomoDerInf2: paciente.tomoDerInf2 === "1",
          tomoDerInf3: paciente.tomoDerInf3 === "1",
          tomoDerInf4: paciente.tomoDerInf4 === "1",
          tomoDerInf5: paciente.tomoDerInf5 === "1",
          tomoDerInf6: paciente.tomoDerInf6 === "1",
          tomoDerInf7: paciente.tomoDerInf7 === "1",
          tomoDerInf8: paciente.tomoDerInf8 === "1",
          zonaDiente: paciente.zonaDiente === "1",
          maxiSuperior: paciente.maxiSuperior === "1",
          maxiInferior: paciente.maxiInferior === "1",
          maxiSupInf: paciente.maxiSupInf === "1",
          caraCompleta: paciente.caraCompleta === "1",
          scanCara: paciente.scanCara === "1",
          tomoATM: paciente.tomoATM === "1",
          ramasMandibular: paciente.ramasMandibular === "1",
          senosParanasales: paciente.senosParanasales === "1",
          narizProFase: paciente.narizProFase === "1",
          viasAereas: paciente.viasAereas === "1",
          vertebras: paciente.vertebras === "1",
          medidasImplantes: paciente.medidasImplantes === "1",
          panoramica: paciente.panoramica === "1",
          perfil: paciente.perfil === "1",
          anteroPosterior: paciente.anteroPosterior === "1",
          posteroAnterior: paciente.posteroAnterior === "1",
          carpograma: paciente.carpograma === "1",
          submentonVertex: paciente.submentonVertex === "1",
          proyeccionWaters: paciente.proyeccionWaters === "1",
          atm: paciente.atm === "1",
          senosMaxilares: paciente.senosMaxilares === "1",
          frente: paciente.frente === "1",
          sonrisa: paciente.sonrisa === "1",
          perfilIzquierdo: paciente.perfilIzquierdo === "1",
          perfilDerecho: paciente.perfilDerecho === "1",
          tresCuartosDerecha: paciente.tresCuartosDerecha === "1",
          tresCuartosIzquierda: paciente.tresCuartosIzquierda === "1",
          baseNariz: paciente.baseNariz === "1",
          basal: paciente.basal === "1",
          tamanoNaturalUnoUno: paciente.tamanoNaturalUnoUno === "1",
          negro: paciente.negro === "1",
          blanco: paciente.blanco === "1",
          azul: paciente.azul === "1",
          deEstudio: paciente.deEstudio === "1",
          deTrabajo: paciente.deTrabajo === "1",
          ortopedia: paciente.ortopedia === "1",
          basicoOrtodoncia: paciente.basicoOrtodoncia === "1",
          basicoOrtodonciaScanner: paciente.basicoOrtodonciaScanner === "1",
          plusOrtodoncia: paciente.plusOrtodoncia === "1",
          plusOrtodonciaJuegoPeriapical:
            paciente.plusOrtodonciaJuegoPeriapical === "1",
          periodoncia: paciente.periodoncia === "1",
          disenoSonrisa: paciente.disenoSonrisa === "1",
          cirujiaMaxilofacial: paciente.cirujiaMaxilofacial === "1",
          rehabilitacion: paciente.rehabilitacion === "1",
          escanerIntraoralADomicilio:
            paciente.escanerIntraoralADomicilio === "1",
          digitalizacionDeModelos: paciente.digitalizacionDeModelos === "1",
          impresionDeModelos: paciente.impresionDeModelos === "1",
          guiasEscaner: paciente.guiasEscaner === "1",
          guiasDiseño: paciente.guiasDiseño === "1",
          guiasImpresion: paciente.guiasImpresion === "1",
          guiasTomografiaGuia: paciente.guiasTomografiaGuia === "1",
          impresion3D: paciente.impresion3D === "1",
          modeloSuperior: paciente.modeloSuperior === "1",
          modeloInferior: paciente.modeloInferior === "1",
          soloCD: paciente.soloCD === "1",
          soloAcetato: paciente.soloAcetato === "1",
          cdPapelFotografico: paciente.cdPapelFotografico === "1",
          usbPapelFotografico: paciente.usbPapelFotografico === "1",
          soloUSB: paciente.soloUSB === "1",
          cdAcetato: paciente.cdAcetato === "1",
          soloPapelFotografico: paciente.soloPapelFotografico === "1",
          usbAcetato: paciente.usbAcetato === "1",
          intraOralesImpreso: paciente.intraOralesImpreso === "1",
          intraOralesVirtual: paciente.intraOralesVirtual === "1",
          extraOralesImpreso: paciente.extraOralesImpreso === "1",
          extraOralesVirtual: paciente.extraOralesVirtual === "1",
        };
        this.setState({
          datosCargados: true,
          paciente: pacienteConBooleans,
        });
      })
      .catch(console.log);
  }

  render() {
    const { datosCargados, paciente } = this.state;
    const tipoUsuario = paciente.tipoUsuario;
    if (!datosCargados) {
      return <div>Cargando...</div>;
    }

    return (
      <div className="card shadow-sm poppins-regular">
        <div className="card-header bg-dark text-white fw-bold d-flex justify-content-between">
          <div className="card-header fw-bold border-bottom-0 h4 pt-3">
            VISTA DE PACIENTE
          </div>
          <div className="card-header border-bottom-0 d-flex align-items-center">
            <label className="me-2 poppins-light" htmlFor="fechaActual">
              Fecha de Ingreso
            </label>
            <span>{paciente.fechaActual}</span> {/* Muestra la fecha actual */}
          </div>
        </div>
        <div className="card-body border border-success ">
          <form onSubmit={this.enviarDatos}>
            <div>
              <h3 className="text-center bg-success text-white">
                Información Personal
              </h3>
              <fieldset disabled>
                <div className="row mb-3">
                  <div className="form-group col-md-5">
                    <label htmlFor="nombre">Nombre y Apellido</label>
                    <input
                      type="text"
                      name="nombre"
                      onChange={this.cambioValor}
                      value={paciente.nombre}
                      id="nombre"
                      className="form-control italic-placeholder"
                      placeholder="Ingrese el nombre completo"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="identificacion">Identificación</label>
                    <input
                      type="text"
                      name="identificacion"
                      onChange={this.cambioValor}
                      value={paciente.identificacion}
                      id="identificacion"
                      className="form-control italic-placeholder"
                      placeholder="CC/CE. No."
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="celular">Celular</label>
                    <input
                      type="text"
                      name="celular"
                      onChange={this.cambioValor}
                      value={paciente.celular}
                      id="celular"
                      className="form-control italic-placeholder"
                      placeholder=""
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset disabled>
                <div className="row mb-3 ">
                  <div className="form-group col-md-3">
                    <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
                    <input
                      type="date"
                      name="fechaNacimiento"
                      onChange={this.cambioValor}
                      value={paciente.fechaNacimiento}
                      id="fechaNacimiento"
                      className="form-control italic-placeholder"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <label htmlFor="edad">Edad</label>
                    <input
                      type="number"
                      name="edad"
                      onChange={this.cambioValor}
                      value={paciente.edad}
                      id="edad"
                      className="form-control italic-placeholder"
                      placeholder="Ingrese la edad"
                      min="0"
                      max="100"
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="nombreUsuario">Doctor</label>
                    <input
                      type="text"
                      name="nombreUsuario"
                      onChange={this.cambioValor}
                      value={paciente.nombreUsuario.toUpperCase()}
                      id="nombreUsuario"
                      className="form-control italic-placeholder"
                      placeholder="nombre del doctor"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            {/* primera seccion */}
            <div className="form-group d-flex">
              <div className="form-group col-4 border border-success">
                <h3 className="text-center bg-success text-white">
                  RX Intra Orales
                </h3>
                <div className="form-group d-flex col-12">
                  <div className="form-group col-1 text-center align-self-center pt-1">
                    <label>DER.</label>
                  </div>
                  {/* div de los chechbox de numeros */}
                  <div className="form-group col-10 d-flex justify-content-center">
                    {/* lado izquierdo */}
                    <fieldset disabled>
                      <div>
                        {/* lado izquierdo arriba*/}
                        <div className="d-flex border-bottom pb-1">
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba8"
                            >
                              8
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba8"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba8}
                              id="izqArriba8"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba7"
                            >
                              7
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba7"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba7}
                              id="izqArriba7"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba6"
                            >
                              6
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba6"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba6}
                              id="izqArriba6"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba5"
                            >
                              5
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba5"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba5}
                              id="izqArriba5"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba4"
                            >
                              4
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba4"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba4}
                              id="izqArriba4"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba3"
                            >
                              3
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba3"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba3}
                              id="izqArriba3"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba2"
                            >
                              2
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba2"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba2}
                              id="izqArriba2"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="izqArriba1"
                            >
                              1
                            </label>
                            <input
                              type="checkbox"
                              name="izqArriba1"
                              onChange={this.cambioValor}
                              checked={paciente.izqArriba1}
                              id="izqArriba1"
                              className="form-check-input mx-auto"
                            />
                          </div>
                        </div>
                        {/* lado izquierdo abajo*/}
                        <div className="d-flex ">
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo8"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo8}
                              id="izqAbajo8"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo8"
                            >
                              8
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo7"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo7}
                              id="izqAbajo7"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo7"
                            >
                              7
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo6"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo6}
                              id="izqAbajo6"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo6"
                            >
                              6
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo5"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo5}
                              id="izqAbajo5"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo5"
                            >
                              5
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo4"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo4}
                              id="izqAbajo4"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo4"
                            >
                              4
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo3"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo3}
                              id="izqAbajo3"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo3"
                            >
                              3
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo2"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo2}
                              id="izqAbajo2"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo2"
                            >
                              2
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="izqAbajo1"
                              onChange={this.cambioValor}
                              checked={paciente.izqAbajo1}
                              id="izqAbajo1"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="izqAbajo1"
                            >
                              1
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {/* lado derecho */}
                    <fieldset disabled>
                      <div className="border-start ms-1">
                        {/* lado derecho arriba */}
                        <div className="d-flex border-bottom pb-1">
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba1"
                            >
                              1
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba1"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba1}
                              id="derArriba1"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba2"
                            >
                              2
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba2"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba2}
                              id="derArriba2"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba3"
                            >
                              3
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba3"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba3}
                              id="derArriba3"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba4"
                            >
                              4
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba4"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba4}
                              id="derArriba4"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba5"
                            >
                              5
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba5"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba5}
                              id="derArriba5"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba6"
                            >
                              6
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba6"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba6}
                              id="derArriba6"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba7"
                            >
                              7
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba7"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba7}
                              id="derArriba7"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="derArriba8"
                            >
                              8
                            </label>
                            <input
                              type="checkbox"
                              name="derArriba8"
                              onChange={this.cambioValor}
                              checked={paciente.derArriba8}
                              id="derArriba8"
                              className="form-check-input mx-auto"
                            />
                          </div>
                        </div>
                        {/* lado derecho abajo */}
                        <div className="d-flex ">
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo1"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo1}
                              id="derAbajo1"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo1"
                            >
                              1
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo2"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo2}
                              id="derAbajo2"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo2"
                            >
                              2
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo3"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo3}
                              id="derAbajo3"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo3"
                            >
                              3
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo4"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo4}
                              id="derAbajo4"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo4"
                            >
                              4
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo5"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo5}
                              id="derAbajo5"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo5"
                            >
                              5
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo6"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo6}
                              id="derAbajo6"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo6"
                            >
                              6
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo7"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo7}
                              id="derAbajo7"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo7"
                            >
                              7
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="derAbajo8"
                              onChange={this.cambioValor}
                              checked={paciente.derAbajo8}
                              id="derAbajo8"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="derAbajo8"
                            >
                              8
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="form-group col-1 align-self-center pt-1">
                    <label>IZQ.</label>
                  </div>
                </div>

                <br />
                <br />
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="periComp"
                          onChange={this.cambioValor}
                          checked={paciente.periComp}
                          id="periComp"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="periComp">
                          Periapical Completo
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="parcial"
                          onChange={this.cambioValor}
                          checked={paciente.parcial}
                          id="parcial"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="parcial">
                          Parcial
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="milimetrada"
                          onChange={this.cambioValor}
                          checked={paciente.milimetrada}
                          id="milimetrada"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="milimetrada"
                        >
                          Milimetrada
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="compCoronales"
                          onChange={this.cambioValor}
                          checked={paciente.compCoronales}
                          id="compCoronales"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="compCoronales"
                        >
                          Completo con Coronales
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="coronalIzq"
                          onChange={this.cambioValor}
                          checked={paciente.coronalIzq}
                          id="coronalIzq"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="coronalIzq"
                        >
                          Coronal Izquierda
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="coronalDer"
                          onChange={this.cambioValor}
                          checked={paciente.coronalDer}
                          id="coronalDer"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="coronalDer"
                        >
                          Coronal Derecha
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusalSup"
                          onChange={this.cambioValor}
                          checked={paciente.oclusalSup}
                          id="oclusalSup"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusalSup"
                        >
                          Oclusal Superior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusalInf"
                          onChange={this.cambioValor}
                          checked={paciente.oclusalInf}
                          id="oclusalInf"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusalInf"
                        >
                          Oclusal Inferior
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* //// aqui */}
                  <div className="form-group">
                    <div className="form-group">
                      <h3 className="text-center">
                        <span className="bg-success text-white ps-1 pe-1">
                          Forma de envío
                        </span>
                      </h3>
                      <div className="form-group d-flex">
                        <div className="form-check col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="intraOralesVirtual"
                              onChange={this.cambioValor}
                              checked={paciente.intraOralesVirtual}
                              id="intraOralesVirtual"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="intraOralesVirtual"
                            >
                              Virtual
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="intraOralesImpreso"
                              onChange={this.cambioValor}
                              checked={paciente.intraOralesImpreso}
                              id="intraOralesImpreso"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="intraOralesImpreso"
                            >
                              Impreso
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* hasta aqui */}
                </fieldset>
              </div>
              <div className="form-group col-4">
                <h3 className="text-center bg-success text-white">
                  Fotografia Intra Orales
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionFrente"
                          onChange={this.cambioValor}
                          checked={paciente.oclusionFrente}
                          id="oclusionFrente"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionFrente"
                        >
                          Oclusión Frente
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionIzquierda"
                          onChange={this.cambioValor}
                          checked={paciente.oclusionIzquierda}
                          id="oclusionIzquierda"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionIzquierda"
                        >
                          Oclusión Izquierda
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionDerecha"
                          onChange={this.cambioValor}
                          checked={paciente.oclusionDerecha}
                          id="oclusionDerecha"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionDerecha"
                        >
                          Oclusión Derecha
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="arcoSuperior"
                          onChange={this.cambioValor}
                          checked={paciente.arcoSuperior}
                          id="arcoSuperior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="arcoSuperior"
                        >
                          Arco Superior
                        </label>
                      </div>
                    </div>
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="arcoInferior"
                          onChange={this.cambioValor}
                          checked={paciente.arcoInferior}
                          id="arcoInferior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="arcoInferior"
                        >
                          Arco Inferior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="overJet"
                          onChange={this.cambioValor}
                          checked={paciente.overJet}
                          id="overJet"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="overJet">
                          Over-jet
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="overBite"
                          onChange={this.cambioValor}
                          checked={paciente.overBite}
                          id="overBite"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="overBite">
                          Over-bite
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">
                    Fotos Extra Orales
                  </h3>
                  <fieldset disabled>
                    <div className="form-group d-flex">
                      <div className="form-check">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="frente"
                            onChange={this.cambioValor}
                            checked={paciente.frente}
                            id="frente"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="frente">
                            Frente
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="sonrisa"
                            onChange={this.cambioValor}
                            checked={paciente.sonrisa}
                            id="sonrisa"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="sonrisa">
                            Sonrisa
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="perfilIzquierdo"
                            onChange={this.cambioValor}
                            checked={paciente.perfilIzquierdo}
                            id="perfilIzquierdo"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="perfilIzquierdo"
                          >
                            Perfil Izquierdo
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="perfilDerecho"
                            onChange={this.cambioValor}
                            checked={paciente.perfilDerecho}
                            id="perfilDerecho"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="perfilDerecho"
                          >
                            Perfil Derecho
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="tresCuartosDerecha"
                            onChange={this.cambioValor}
                            checked={paciente.tresCuartosDerecha}
                            id="tresCuartosDerecha"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tresCuartosDerecha"
                          >
                            3/4 Derecha
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="tresCuartosIzquierda"
                            onChange={this.cambioValor}
                            checked={paciente.tresCuartosIzquierda}
                            id="tresCuartosIzquierda"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tresCuartosIzquierda"
                          >
                            3/4 Izquierda
                          </label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="baseNariz"
                            onChange={this.cambioValor}
                            checked={paciente.baseNariz}
                            id="baseNariz"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="baseNariz"
                          >
                            Base Nariz
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="basal"
                            onChange={this.cambioValor}
                            checked={paciente.basal}
                            id="basal"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="basal">
                            Basal
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="tamanoNaturalUnoUno"
                            onChange={this.cambioValor}
                            checked={paciente.tamanoNaturalUnoUno}
                            id="tamanoNaturalUnoUno"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tamanoNaturalUnoUno"
                          >
                            Tamaño Natural 1-1
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="form-group col-4 border border-danger">
                <h3 className="text-center bg-danger text-white">
                  Tomografía 3D
                </h3>
                <div className="form-group d-flex col-12">
                  <div className="form-group col-1 text-center align-self-center pt-1">
                    <label>DER.</label>
                  </div>
                  {/* div de los chechbox de numeros */}
                  <div className="form-group col-10 d-flex justify-content-center">
                    {/* lado izquierdo */}
                    <fieldset disabled>
                      <div>
                        {/* lado izquierdo arriba*/}
                        <div className="d-flex border-bottom pb-1">
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup8"
                            >
                              8
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup8"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup8}
                              id="tomoIzqSup8"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup7"
                            >
                              7
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup7"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup7}
                              id="tomoIzqSup7"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup6"
                            >
                              6
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup6"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup6}
                              id="tomoIzqSup6"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup5"
                            >
                              5
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup5"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup5}
                              id="tomoIzqSup5"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup4"
                            >
                              4
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup4"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup4}
                              id="tomoIzqSup4"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup3"
                            >
                              3
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup3"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup3}
                              id="tomoIzqSup3"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup2"
                            >
                              2
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup2"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup2}
                              id="tomoIzqSup2"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoIzqSup1"
                            >
                              1
                            </label>
                            <input
                              type="checkbox"
                              name="tomoIzqSup1"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqSup1}
                              id="tomoIzqSup1"
                              className="form-check-input mx-auto"
                            />
                          </div>
                        </div>
                        {/* lado izquierdo abajo*/}
                        <div className="d-flex ">
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf8"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf8}
                              id="tomoIzqInf8"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf8"
                            >
                              8
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf7"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf7}
                              id="tomoIzqInf7"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf7"
                            >
                              7
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf6"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf6}
                              id="tomoIzqInf6"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf6"
                            >
                              6
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf5"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf5}
                              id="tomoIzqInf5"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf5"
                            >
                              5
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf4"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf4}
                              id="tomoIzqInf4"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf4"
                            >
                              4
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf3"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf3}
                              id="tomoIzqInf3"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf3"
                            >
                              3
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf2"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf2}
                              id="tomoIzqInf2"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf2"
                            >
                              2
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoIzqInf1"
                              onChange={this.cambioValor}
                              checked={paciente.tomoIzqInf1}
                              id="tomoIzqInf1"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoIzqInf1"
                            >
                              1
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {/* lado derecho */}
                    <fieldset disabled>
                      <div className="border-start ms-1">
                        {/* lado derecho arriba */}
                        <div className="d-flex border-bottom pb-1">
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup1"
                            >
                              1
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup1"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup1}
                              id="tomoDerSup1"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup2"
                            >
                              2
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup2"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup2}
                              id="tomoDerSup2"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup3"
                            >
                              3
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup3"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup3}
                              id="tomoDerSup3"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup4"
                            >
                              4
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup4"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup4}
                              id="tomoDerSup4"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup5"
                            >
                              5
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup5"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup5}
                              id="tomoDerSup5"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup6"
                            >
                              6
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup6"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup6}
                              id="tomoDerSup6"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup7"
                            >
                              7
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup7"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup7}
                              id="tomoDerSup7"
                              className="form-check-input mx-auto"
                            />
                          </div>
                          <div className="form-check text-center ps-1">
                            <label
                              className="form-check-label d-block desactivado"
                              htmlFor="tomoDerSup8"
                            >
                              8
                            </label>
                            <input
                              type="checkbox"
                              name="tomoDerSup8"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerSup8}
                              id="tomoDerSup8"
                              className="form-check-input mx-auto"
                            />
                          </div>
                        </div>
                        {/* lado derecho abajo */}
                        <div className="d-flex ">
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf1"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf1}
                              id="tomoDerInf1"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf1"
                            >
                              1
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf2"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf2}
                              id="tomoDerInf2"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf2"
                            >
                              2
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf3"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf3}
                              id="tomoDerInf3"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf3"
                            >
                              3
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf4"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf4}
                              id="tomoDerInf4"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf4"
                            >
                              4
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf5"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf5}
                              id="tomoDerInf5"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf5"
                            >
                              5
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf6"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf6}
                              id="tomoDerInf6"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf6"
                            >
                              6
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf7"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf7}
                              id="tomoDerInf7"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf7"
                            >
                              7
                            </label>
                          </div>
                          <div className="form-check text-center ps-1">
                            <input
                              type="checkbox"
                              name="tomoDerInf8"
                              onChange={this.cambioValor}
                              checked={paciente.tomoDerInf8}
                              id="tomoDerInf8"
                              className="form-check-input mx-auto"
                            />
                            <label
                              className="form-check-label d-block "
                              htmlFor="tomoDerInf8"
                            >
                              8
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="form-group col-1 align-self-center pt-1">
                    <label>IZQ.</label>
                  </div>
                </div>
                <br />
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="zonaDiente"
                          onChange={this.cambioValor}
                          checked={paciente.zonaDiente}
                          id="zonaDiente"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="zonaDiente"
                        >
                          Zona Diente(s)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="narizProFase"
                          onChange={this.cambioValor}
                          checked={paciente.narizProFase}
                          id="narizProFase"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="narizProFase"
                        >
                          Zona Diente alta resolución (0.75)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="medidasImplantes"
                          onChange={this.cambioValor}
                          checked={paciente.medidasImplantes}
                          id="medidasImplantes"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="medidasImplantes"
                        >
                          Cuadrante
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="maxiSuperior"
                          onChange={this.cambioValor}
                          checked={paciente.maxiSuperior}
                          id="maxiSuperior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="maxiSuperior"
                        >
                          Maxilar Superior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="maxiInferior"
                          onChange={this.cambioValor}
                          checked={paciente.maxiInferior}
                          id="maxiInferior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="maxiInferior"
                        >
                          Maxilar Inferior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="maxiSupInf"
                          onChange={this.cambioValor}
                          checked={paciente.maxiSupInf}
                          id="maxiSupInf"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="maxiSupInf"
                        >
                          Maxilar Superior e Inferior
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="caraCompleta"
                          onChange={this.cambioValor}
                          checked={paciente.caraCompleta}
                          id="caraCompleta"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="caraCompleta"
                        >
                          Cara Completa
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="scanCara"
                          onChange={this.cambioValor}
                          checked={paciente.scanCara}
                          id="scanCara"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="scanCara">
                          Scan Cara
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="tomoATM"
                          onChange={this.cambioValor}
                          checked={paciente.tomoATM}
                          id="tomoATM"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="tomoATM">
                          ATM
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="ramasMandibular"
                          onChange={this.cambioValor}
                          checked={paciente.ramasMandibular}
                          id="ramasMandibular"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ramasMandibular"
                        >
                          Ramas Mandibulares
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="senosParanasales"
                          onChange={this.cambioValor}
                          checked={paciente.senosParanasales}
                          id="senosParanasales"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="senosParanasales"
                        >
                          Senos Paranasales
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="viasAereas"
                          onChange={this.cambioValor}
                          checked={paciente.viasAereas}
                          id="viasAereas"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="viasAereas"
                        >
                          Vías Aéreas
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="vertebras"
                          onChange={this.cambioValor}
                          checked={paciente.vertebras}
                          id="vertebras"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="vertebras">
                          Vertebras
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            {/* SEGUNDA SECCION */}
            <div className="form-group d-flex">
              <div className="form-group col-4 border border-success">
                <h3 className="text-center bg-success text-white">
                  RX Extra Orales
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="panoramica"
                          onChange={this.cambioValor}
                          checked={paciente.panoramica}
                          id="panoramica"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="panoramica"
                        >
                          Panorámica
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="perfil"
                          onChange={this.cambioValor}
                          checked={paciente.perfil}
                          id="perfil"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="perfil">
                          Perfil
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="anteroPosterior"
                          onChange={this.cambioValor}
                          checked={paciente.anteroPosterior}
                          id="anteroPosterior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="anteroPosterior"
                        >
                          Antero Posterior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="posteroAnterior"
                          onChange={this.cambioValor}
                          checked={paciente.posteroAnterior}
                          id="posteroAnterior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="posteroAnterior"
                        >
                          Postero Anterior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="carpograma"
                          onChange={this.cambioValor}
                          checked={paciente.carpograma}
                          id="carpograma"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="carpograma"
                        >
                          Carpograma
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="submentonVertex"
                          onChange={this.cambioValor}
                          checked={paciente.submentonVertex}
                          id="submentonVertex"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="submentonVertex"
                        >
                          Submenton Vertex
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="proyeccionWaters"
                          onChange={this.cambioValor}
                          checked={paciente.proyeccionWaters}
                          id="proyeccionWaters"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="proyeccionWaters"
                        >
                          Proyección Waters
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="atm"
                          onChange={this.cambioValor}
                          checked={paciente.atm}
                          id="atm"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="atm">
                          ATM
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="senosMaxilares"
                          onChange={this.cambioValor}
                          checked={paciente.senosMaxilares}
                          id="senosMaxilares"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="senosMaxilares"
                        >
                          Senos Maxilares
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                {/* //// aqui */}
                <div className="form-group">
                  <div className="form-group">
                    <h3 className="text-center">
                      <span className="bg-success text-white ps-1 pe-1">
                        Forma de envío
                      </span>
                    </h3>
                    <fieldset disabled>
                      <div className="form-group d-flex">
                        <div className="form-check col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="extraOralesVirtual"
                              onChange={this.cambioValor}
                              checked={paciente.extraOralesVirtual}
                              id="extraOralesVirtual"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="extraOralesVirtual"
                            >
                              Virtual
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="extraOralesImpreso"
                              onChange={this.cambioValor}
                              checked={paciente.extraOralesImpreso}
                              id="extraOralesImpreso"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="extraOralesImpreso"
                            >
                              Impreso
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                {/* hasta aqui */}
              </div>
              <div className="form-group col-3">
                <h3 className="text-center bg-success text-white">
                  Diagnosticos
                </h3>
                <div className="form-group d-flex">
                  <fieldset disabled>
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="steiner"
                          onChange={this.cambioValor}
                          checked={paciente.steiner}
                          id="steiner"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="steiner">
                          Steiner
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="mcnamara"
                          onChange={this.cambioValor}
                          checked={paciente.mcnamara}
                          id="mcnamara"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="mcnamara">
                          Mcnamara
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="ricketts"
                          onChange={this.cambioValor}
                          checked={paciente.ricketts}
                          id="ricketts"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="ricketts">
                          Ricketts
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="grummons"
                          onChange={this.cambioValor}
                          checked={paciente.grummons}
                          id="grummons"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="grummons">
                          Grummons
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <br />
                <div className="form-group d-flex me-3 ms-3">
                  <div className="input-group">
                    <span className="input-group-text d-flex align-items-center justify-content-center">
                      Otro
                    </span>
                    <textarea
                      className="form-control"
                      aria-label="With textarea"
                      name="otroDiagnostico"
                      value={paciente.otroDiagnostico}
                      onChange={this.cambioValor}
                      disabled
                      id="otroDiagnostico"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="form-group col-2">
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">Fondo</h3>
                  <div className="form-group d-flex">
                    <fieldset disabled>
                      <div className="form-check">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="negro"
                            onChange={this.cambioValor}
                            checked={paciente.negro}
                            id="negro"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="negro">
                            Negro
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="blanco"
                            onChange={this.cambioValor}
                            checked={paciente.blanco}
                            id="blanco"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="blanco">
                            Blanco
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="azul"
                            onChange={this.cambioValor}
                            checked={paciente.azul}
                            id="azul"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="azul">
                            Azul
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">Modelos</h3>
                  <div className="form-group d-flex">
                    <fieldset disabled>
                      <div className="form-check">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="deEstudio"
                            onChange={this.cambioValor}
                            checked={paciente.deEstudio}
                            id="deEstudio"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="deEstudio"
                          >
                            De Estudio
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="deTrabajo"
                            onChange={this.cambioValor}
                            checked={paciente.deTrabajo}
                            id="deTrabajo"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="deTrabajo"
                          >
                            De Trabajo
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="form-group col-4 border border-danger border-bottom-0">
                <h3 className="text-center bg-danger text-white">
                  Forma de Entrega <br /> Tomografías
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="soloAcetato"
                          onChange={this.cambioValor}
                          checked={paciente.soloAcetato}
                          id="soloAcetato"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="cdAcetato">
                          Solo Acetato
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="soloUSB"
                          onChange={this.cambioValor}
                          checked={paciente.soloUSB}
                          id="soloUSB"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="soloUSB">
                          Solo USB
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="soloCD"
                          onChange={this.cambioValor}
                          checked={paciente.soloCD}
                          id="soloCD"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="soloCD">
                          Solo VIRTUAL
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="cdPapelFotografico"
                          onChange={this.cambioValor}
                          checked={paciente.cdPapelFotografico}
                          id="cdPapelFotografico"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cdPapelFotografico"
                        >
                          DICOM
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="soloPapelFotografico"
                          onChange={this.cambioValor}
                          checked={paciente.soloPapelFotografico}
                          id="soloPapelFotografico"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="soloPapelFotografico"
                        >
                          Solo Papel Fotográfico
                        </label>
                      </div>
                    </div>
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="cdAcetato"
                          onChange={this.cambioValor}
                          checked={paciente.cdAcetato}
                          id="cdAcetato"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="cdAcetato">
                          Solo Plantillas al Correo
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="usbAcetato"
                          onChange={this.cambioValor}
                          checked={paciente.usbAcetato}
                          id="usbAcetato"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="usbAcetato"
                        >
                          USB + Acetato
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="usbPapelFotografico"
                          onChange={this.cambioValor}
                          checked={paciente.usbPapelFotografico}
                          id="usbPapelFotografico"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="usbPapelFotografico"
                        >
                          USB + Papel Fotográfico
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.cambioValor}
                          checked={""}
                          id=""
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="">
                          LECTURA
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            {/* TERCERA SECCION */}
            <div className="form-group d-flex">
              <div className="form-group col-6">
                <h3 className="text-center bg-success text-white">
                  Paquetes <br />
                  Diagnósticos
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="ortopedia"
                          onChange={this.cambioValor}
                          checked={paciente.ortopedia}
                          id="ortopedia"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="ortopedia">
                          Ortopedia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="basicoOrtodoncia"
                          onChange={this.cambioValor}
                          checked={paciente.basicoOrtodoncia}
                          id="basicoOrtodoncia"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="basicoOrtodoncia"
                        >
                          Básico de Ortodoncia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="basicoOrtodonciaScanner"
                          onChange={this.cambioValor}
                          checked={paciente.basicoOrtodonciaScanner}
                          id="basicoOrtodonciaScanner"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="basicoOrtodonciaScanner"
                        >
                          Básico de Ortodoncia + Scanner
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="plusOrtodoncia"
                          onChange={this.cambioValor}
                          checked={paciente.plusOrtodoncia}
                          id="plusOrtodoncia"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="plusOrtodoncia"
                        >
                          Plus de Ortodoncia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="plusOrtodonciaJuegoPeriapical"
                          onChange={this.cambioValor}
                          checked={paciente.plusOrtodonciaJuegoPeriapical}
                          id="plusOrtodonciaJuegoPeriapical"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="plusOrtodonciaJuegoPeriapical"
                        >
                          Plus de Ortod. + Juego Periapical
                        </label>
                      </div>
                    </div>
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="periodoncia"
                          onChange={this.cambioValor}
                          checked={paciente.periodoncia}
                          id="periodoncia"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="periodoncia"
                        >
                          Periodoncia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="disenoSonrisa"
                          onChange={this.cambioValor}
                          checked={paciente.disenoSonrisa}
                          id="disenoSonrisa"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="disenoSonrisa"
                        >
                          Diseño Sonrisa
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="cirujiaMaxilofacial"
                          onChange={this.cambioValor}
                          checked={paciente.cirujiaMaxilofacial}
                          id="cirujiaMaxilofacial"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cirujiaMaxilofacial"
                        >
                          Cirujía Maxilofacial
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="rehabilitacion"
                          onChange={this.cambioValor}
                          checked={paciente.rehabilitacion}
                          id="rehabilitacion"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rehabilitacion"
                        >
                          Rehabilitación
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="form-group col-2">
                <h3 className="text-center bg-danger text-white">
                  Escáner Intra <br /> Oral
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="escanerIntraoralADomicilio"
                          onChange={this.cambioValor}
                          checked={paciente.escanerIntraoralADomicilio}
                          id="escanerIntraoralADomicilio"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="escanerIntraoralADomicilio"
                        >
                          Escáner Intraoral
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="digitalizacionDeModelos"
                          onChange={this.cambioValor}
                          checked={paciente.digitalizacionDeModelos}
                          id="digitalizacionDeModelos"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="digitalizacionDeModelos"
                        >
                          Escáner a modelos
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="impresionDeModelos"
                          onChange={this.cambioValor}
                          checked={paciente.impresionDeModelos}
                          id="impresionDeModelos"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="impresionDeModelos"
                        >
                          Impresión de modelos
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="form-group col-2">
                <h3 className="text-center bg-danger text-white">
                  Guías <br /> Quirurgicas
                </h3>
                <fieldset disabled>
                  <div className="form-group d-flex">
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="guiasEscaner"
                          onChange={this.cambioValor}
                          checked={paciente.guiasEscaner}
                          id="guiasEscaner"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="guiasEscaner"
                        >
                          Escáner
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="guiasDiseño"
                          onChange={this.cambioValor}
                          checked={paciente.guiasDiseño}
                          id="guiasDiseño"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="guiasDiseño"
                        >
                          Diseño
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="guiasImpresion"
                          onChange={this.cambioValor}
                          checked={paciente.guiasImpresion}
                          id="guiasImpresion"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="guiasImpresion"
                        >
                          Impresión
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="guiasTomografiaGuia"
                          onChange={this.cambioValor}
                          checked={paciente.guiasTomografiaGuia}
                          id="guiasTomografiaGuia"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="guiasTomografiaGuia"
                        >
                          Tomografía para Guía
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="form-group col-2">
                <div className="form-group">
                  <div className="form-group">
                    <h3 className="text-center bg-danger text-white">
                      Modelos Digitales
                    </h3>
                    <fieldset disabled>
                      <div className="form-group">
                        <div className="form-check">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="impresion3D"
                              onChange={this.cambioValor}
                              checked={paciente.impresion3D}
                              id="impresion3D"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="impresion3D"
                            >
                              Impresión 3D
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="modeloSuperior"
                              onChange={this.cambioValor}
                              checked={paciente.modeloSuperior}
                              id="modeloSuperior"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="modeloSuperior"
                            >
                              Modelo Superior
                            </label>
                          </div>
                        </div>
                        <div className="form-check col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="modeloInferior"
                              onChange={this.cambioValor}
                              checked={paciente.modeloInferior}
                              id="modeloInferior"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="modeloInferior"
                            >
                              Modelo Inferior
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            {/* SECCION OBSERVACIONES */}
            <fieldset disabled>
              <div className="form-group bg-dark text-white">
                <div className="text-center">
                  <h3 className="text-white fw-bold pt-2">OBSERVACIONES</h3>
                </div>
                <div className="d-flex bg-secondary form-group">
                  <div className="form-group col-6 border border-white rounded-top-3">
                    <h3 className="d-flex align-items-center justify-content-center pt-2">
                      Radiológico
                    </h3>
                    <div
                      className={`relative-container ${
                        tipoUsuario === "doctor" ? "disabled" : ""
                      }`}
                    >
                      <textarea
                        id="observacionAdministrador"
                        name="observacionAdministrador"
                        value={paciente.observacionAdministrador}
                        onChange={this.cambioValor}
                        className="form-control"
                        rows="3"
                        // placeholder={
                        //   tipoUsuario === "doctor"
                        //     ? ""
                        //     : "Escribe aquí las observaciones del Radiológico"
                        // }
                        // disabled={tipoUsuario === "doctor"}
                      ></textarea>
                      {/* {tipoUsuario === "doctor" && ( 
                      <div className="placeholder-text">
                        <i className="fas fa-lock"></i> Solo para Radiológico
                      </div>
                    )}  */}
                    </div>
                  </div>
                  <div className="form-group col-6 border border-white rounded-top-3">
                    <h3 className="d-flex align-items-center justify-content-center pt-2">
                      Doctor
                    </h3>
                    <div
                    // className={`relative-container ${
                    //   tipoUsuario === "administrador" ? "disabled" : ""
                    // }`}
                    >
                      <textarea
                        id="observacionDoctor"
                        name="observacionDoctor"
                        value={paciente.observacionDoctor}
                        onChange={this.cambioValor}
                        className="form-control"
                        rows="3"
                        // placeholder={
                        //   tipoUsuario === "administrador"
                        //     // ? ""
                        //     // : "Escribe aquí las observaciones del Doctor"
                        // }
                        // disabled={tipoUsuario === "administrador"}
                      ></textarea>
                      {/* {tipoUsuario === "administrador" && ( */}
                      {/* <div className="placeholder-text">
                        <i className="fas fa-lock"></i> Solo para Doctores
                      </div> */}
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* SECCION BOTONES*/}
            <br />
            <div className="d-flex justify-content-center  ">
              {/* <button type="submit" className="btn btn-success me-2 col-6">
                Actualizar
              </button> */}

              <button
                onClick={this.confirmarCancelar}
                className="btn btn-primary col-3"
              >
                <i className="fa-solid fa-angles-left"></i> Volver
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer text-muted bg-success"></div>
      </div>
    );
  }
}

export default withRouter(Ver);
