import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";

const EditarDoctor = () => {
  const { id } = useParams();
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [tipo, setTipo] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const history = useHistory();

  useEffect(() => {
    const fetchDoctor = async () => {
      try {
        const response = await axios.get(
          `/obtenerDoctor.php?id=${id}`
        );
        const data = response.data;
        if (data.success) {
          const doctor = data.doctor;
          setNombre(doctor.nombre);
          setCorreo(doctor.correo);
          setContrasena(doctor.contrasena);
          setTipo(doctor.tipo);
        } else {
          setError(data.message || "Error al obtener los datos del doctor");
        }
      } catch (error) {
        setError("Error al conectar con el servidor");
      }
    };

    fetchDoctor();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "/editarDoctor.php",
        { id, nombre, correo, contrasena, tipo }
      );
      const data = response.data;
      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Doctor actualizado",
          text: `El doctor ${nombre} ha sido actualizado correctamente.`,
          showConfirmButton: true,
          confirmButtonText: "Volver al listado",
        }).then(() => {
          history.push("/lista-doctores");
        });
      } else {
        setError(data.message || "Error al actualizar el doctor");
      }
    } catch (error) {
      setError("Error al conectar con el servidor");
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: "¿Está seguro de cancelar?",
      text: "Todos los cambios no guardados se perderán.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cancelar",
      cancelButtonText: "No, regresar",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/lista-doctores");
      }
    });
  };

  return (
    <div className="card bg-body rounded poppins-regular">
      <div className="card-header bg-dark text-white d-flex pt-4">
        <h3 className="fw-bold h4">EDITAR DOCTOR</h3>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 fondoInformacionDoctor text-white">
            <h3 className="text-center">Información del Doctor</h3>
          </div>
          <div className="mb-4 d-flex">
            <div className="row mb-4 justify-content-center col-3 me-3">
              <div className="form-group">
                <label htmlFor="nombre" className="fw-bold">
                  Nombre
                </label>
                <input
                  type="text"
                  id="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  className="form-control border-success shadow-sm"
                  placeholder="Ingrese el nombre del doctor"
                  required
                />
              </div>
            </div>
            <div className="row mb-4 justify-content-center col-3 me-3">
              <div className="form-group">
                <label htmlFor="correo" className="fw-bold">
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  id="correo"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  className="form-control border-success shadow-sm"
                  placeholder="Ingrese el correo del doctor"
                  required
                />
              </div>
            </div>
            <div className="row mb-4 justify-content-center col-3 me-3 position-relative">
              <div className="form-group position-relative">
                <label htmlFor="contrasena" className="fw-bold">
                  Contraseña
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="contrasena"
                    value={contrasena}
                    onChange={(e) => setContrasena(e.target.value)}
                    className="form-control border-success shadow-sm"
                    placeholder="Ingrese la contraseña"
                    required
                  />
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-4 justify-content-center col-3 me-3">
              <div className="form-group">
                <label htmlFor="tipo" className="fw-bold">
                  Tipo de Usuario
                </label>
                <select
                  id="tipo"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  className="form-select border-success shadow-sm"
                  required
                >
                  <option value="" disabled>
                    Seleccione el tipo de usuario
                  </option>
                  <option value="doctor">Doctor</option>
                  <option value="administrador">Radiológico</option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button type="submit" className="btn btn-success me-2 col-6">
              Actualizar Doctor
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-danger col-6"
            >
              Cancelar
            </button>
          </div>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default EditarDoctor;
