import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../styles/style.css";

const Login = () => {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "/login.php",
        { correo, password }
      );
      const data = response.data;
      if (data.success) {
        localStorage.setItem("sesionActiva", true);
        localStorage.setItem("nombre", data.nombre);
        localStorage.setItem("tipo", data.tipo);

        Swal.fire({
          icon: "success",
          title: `¡Bienvenido, ${data.nombre}!`,
          text: "Has iniciado sesión satisfactoriamente.",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          window.location.href = "/listar";
        });
      } else {
        setError("Credenciales inválidas");
        setCorreo("");
        setPassword("");
      }
    } catch (error) {
      setError("Error al autenticar");
      setCorreo("");
      setPassword("");
    }
  };

  return (
    <div className="login-container">
      <div
        className="login-card bg-success h-100 w-100 logo-login"
        style={{
          backgroundImage: `url(/logo-blanco.png)`,
          backgroundSize: "70%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="login-card bg-light h-100 bg-white poppins-regular d-flex flex-column justify-content-center">
        <h2 className="login-title poppins-bold">Iniciar Sesión</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group f-group f-log poppins-regular">
            <label htmlFor="correo">Correo electrónico</label>
            <input
              type="email"
              id="correo"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              required
            />
          </div>
          <div className="form-group f-group f-log poppins-regular">
            <label htmlFor="password">Contraseña</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="form-control"
              />
              <div className="input-group-append custom-icon-container">
                <span
                  className="input-group-text"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-success f">
            Iniciar Sesión
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default Login;
