import React from "react";
import api from "../servicios/api";
import Swal from "sweetalert2";
import "../styles/style.css";
import { withRouter } from "react-router-dom";

class Crear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaActual: new Date().toLocaleDateString(),
      tipoUsuario: localStorage.getItem("tipo"),
      nombre: "",
      edad: "",
      celular: "",
      // correo: "",
      identificacion: "",
      fechaNacimiento: "",
      otroDiagnostico: "",
      observacionAdministrador: "",
      observacionDoctor: "",
      // fechaCita: "",
      nombreUsuario: localStorage.getItem("nombre") || "",
      // ****************FOTOGRAFIA INTRA ORALES
      oclusionFrente: false,
      oclusionIzquierda: false,
      oclusionDerecha: false,
      arcoSuperior: false,
      arcoInferior: false,
      overJet: false,
      overBite: false,
      // ****************DIAGNOSTICOS
      steiner: false,
      mcnamara: false,
      ricketts: false,
      grummons: false,
      // otroDiagnostico: false,
      // **************** RX Intra Orales
      izqArriba1: false,
      izqArriba2: false,
      izqArriba3: false,
      izqArriba4: false,
      izqArriba5: false,
      izqArriba6: false,
      izqArriba7: false,
      izqArriba8: false,
      izqAbajo1: false,
      izqAbajo2: false,
      izqAbajo3: false,
      izqAbajo4: false,
      izqAbajo5: false,
      izqAbajo6: false,
      izqAbajo7: false,
      izqAbajo8: false,
      derArriba1: false,
      derArriba2: false,
      derArriba3: false,
      derArriba4: false,
      derArriba5: false,
      derArriba6: false,
      derArriba7: false,
      derArriba8: false,
      derAbajo1: false,
      derAbajo2: false,
      derAbajo3: false,
      derAbajo4: false,
      derAbajo5: false,
      derAbajo6: false,
      derAbajo7: false,
      derAbajo8: false,
      periComp: false,
      parcial: false,
      milimetrada: false,
      compCoronales: false,
      coronalIzq: false,
      coronalDer: false,
      oclusalSup: false,
      oclusalInf: false,
      intraOralesVirtual: false,
      intraOralesImpreso: false,
      // **************** Tomografia 3D
      tomoIzqSup1: false,
      tomoIzqSup2: false,
      tomoIzqSup3: false,
      tomoIzqSup4: false,
      tomoIzqSup5: false,
      tomoIzqSup6: false,
      tomoIzqSup7: false,
      tomoIzqSup8: false,
      tomoIzqInf1: false,
      tomoIzqInf2: false,
      tomoIzqInf3: false,
      tomoIzqInf4: false,
      tomoIzqInf5: false,
      tomoIzqInf6: false,
      tomoIzqInf7: false,
      tomoIzqInf8: false,
      tomoDerSup1: false,
      tomoDerSup2: false,
      tomoDerSup3: false,
      tomoDerSup4: false,
      tomoDerSup5: false,
      tomoDerSup6: false,
      tomoDerSup7: false,
      tomoDerSup8: false,
      tomoDerInf1: false,
      tomoDerInf2: false,
      tomoDerInf3: false,
      tomoDerInf4: false,
      tomoDerInf5: false,
      tomoDerInf6: false,
      tomoDerInf7: false,
      tomoDerInf8: false,
      zonaDiente: false,
      maxiSuperior: false,
      maxiInferior: false,
      maxiSupInf: false,
      caraCompleta: false,
      scanCara: false,
      tomoATM: false,
      ramasMandibular: false,
      senosParanasales: false,
      narizProFase: false,
      viasAereas: false,
      vertebras: false,
      medidasImplantes: false,
      // **************** RX Extra Orales
      panoramica: false,
      perfil: false,
      anteroPosterior: false,
      posteroAnterior: false,
      carpograma: false,
      submentonVertex: false,
      proyeccionWaters: false,
      atm: false,
      senosMaxilares: false,
      extraOralesVirtual: false,
      extraOralesImpreso: false,
      // **************** Fotos Extra Orales
      frente: false,
      sonrisa: false,
      perfilIzquierdo: false,
      perfilDerecho: false,
      tresCuartosDerecha: false,
      tresCuartosIzquierda: false,
      baseNariz: false,
      basal: false,
      tamanoNaturalUnoUno: false,
      // **************** Fondo
      negro: false,
      blanco: false,
      azul: false,
      // **************** Modelos
      deEstudio: false,
      deTrabajo: false,
      // **************** Paquetes Diagnosticos
      ortopedia: false,
      basicoOrtodoncia: false,
      basicoOrtodonciaScanner: false,
      plusOrtodoncia: false,
      plusOrtodonciaJuegoPeriapical: false,
      periodoncia: false,
      disenoSonrisa: false,
      cirujiaMaxilofacial: false,
      rehabilitacion: false,
      // **************** Escáner Intra Oral
      escanerIntraoralADomicilio: false,
      digitalizacionDeModelos: false,
      impresionDeModelos: false,
      // **************** Guías Quirurgicas
      guiasEscaner: false,
      guiasDiseño: false,
      guiasImpresion: false,
      guiasTomografiaGuia: false,
      // **************** Modelos Digitales
      impresion3D: false,
      modeloSuperior: false,
      modeloInferior: false,
      // **************** Forma de Entrega Tomografías
      soloCD: false,
      soloAcetato: false,
      cdPapelFotografico: false,
      usbPapelFotografico: false,
      soloUSB: false,
      cdAcetato: false,
      soloPapelFotografico: false,
      usbAcetato: false,
      entregaLectura: false,
    };
  }

  cambioValor = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  };

  enviarDatos = (e) => {
    e.preventDefault();
    // console.log("Formulario enviado...");

    const {
      fechaActual,
      nombre,
      celular,
      edad,
      // correo,
      identificacion,
      fechaNacimiento,
      // fechaCita,
      nombreUsuario,
      // ****************FOTOGRAFIA INTRA ORALES
      oclusionFrente,
      oclusionIzquierda,
      oclusionDerecha,
      arcoSuperior,
      arcoInferior,
      overJet,
      overBite,
      // ****************DIAGNOSTICOS
      steiner,
      mcnamara,
      ricketts,
      grummons,
      otroDiagnostico,
      observacionAdministrador,
      observacionDoctor,
      // **************** RX Intra Orales
      izqArriba1,
      izqArriba2,
      izqArriba3,
      izqArriba4,
      izqArriba5,
      izqArriba6,
      izqArriba7,
      izqArriba8,
      izqAbajo1,
      izqAbajo2,
      izqAbajo3,
      izqAbajo4,
      izqAbajo5,
      izqAbajo6,
      izqAbajo7,
      izqAbajo8,
      derArriba1,
      derArriba2,
      derArriba3,
      derArriba4,
      derArriba5,
      derArriba6,
      derArriba7,
      derArriba8,
      derAbajo1,
      derAbajo2,
      derAbajo3,
      derAbajo4,
      derAbajo5,
      derAbajo6,
      derAbajo7,
      derAbajo8,
      periComp,
      parcial,
      milimetrada,
      compCoronales,
      coronalIzq,
      coronalDer,
      oclusalSup,
      oclusalInf,
      intraOralesVirtual,
      intraOralesImpreso,
      // **************** Tomografia 3D
      tomoIzqSup1,
      tomoIzqSup2,
      tomoIzqSup3,
      tomoIzqSup4,
      tomoIzqSup5,
      tomoIzqSup6,
      tomoIzqSup7,
      tomoIzqSup8,
      tomoIzqInf1,
      tomoIzqInf2,
      tomoIzqInf3,
      tomoIzqInf4,
      tomoIzqInf5,
      tomoIzqInf6,
      tomoIzqInf7,
      tomoIzqInf8,
      tomoDerSup1,
      tomoDerSup2,
      tomoDerSup3,
      tomoDerSup4,
      tomoDerSup5,
      tomoDerSup6,
      tomoDerSup7,
      tomoDerSup8,
      tomoDerInf1,
      tomoDerInf2,
      tomoDerInf3,
      tomoDerInf4,
      tomoDerInf5,
      tomoDerInf6,
      tomoDerInf7,
      tomoDerInf8,
      zonaDiente,
      maxiSuperior,
      maxiInferior,
      maxiSupInf,
      caraCompleta,
      scanCara,
      tomoATM,
      ramasMandibular,
      senosParanasales,
      narizProFase,
      viasAereas,
      vertebras,
      medidasImplantes,
      // **************** RX Extra Orales
      panoramica,
      perfil,
      anteroPosterior,
      posteroAnterior,
      carpograma,
      submentonVertex,
      proyeccionWaters,
      atm,
      senosMaxilares,
      extraOralesVirtual,
      extraOralesImpreso,
      // **************** Fotos Extra Orales
      frente,
      sonrisa,
      perfilIzquierdo,
      perfilDerecho,
      tresCuartosDerecha,
      tresCuartosIzquierda,
      baseNariz,
      basal,
      tamanoNaturalUnoUno,
      // **************** Fondo
      negro,
      blanco,
      azul,
      // **************** Modelos
      deEstudio,
      deTrabajo,
      // **************** Paquetes Diagnosticos
      ortopedia,
      basicoOrtodoncia,
      basicoOrtodonciaScanner,
      plusOrtodoncia,
      plusOrtodonciaJuegoPeriapical,
      periodoncia,
      disenoSonrisa,
      cirujiaMaxilofacial,
      rehabilitacion,
      // **************** Escáner Intra Oral
      escanerIntraoralADomicilio,
      digitalizacionDeModelos,
      impresionDeModelos,
      // **************** Guías Quirurgicas
      guiasEscaner,
      guiasDiseño,
      guiasImpresion,
      guiasTomografiaGuia,
      // **************** Modelos Digitales
      impresion3D,
      modeloSuperior,
      modeloInferior,
      // **************** Forma de Entrega Tomografías
      soloCD,
      soloAcetato,
      cdPapelFotografico,
      usbPapelFotografico,
      soloUSB,
      cdAcetato,
      soloPapelFotografico,
      usbAcetato,
      entregaLectura,
    } = this.state;

    const datosEnviar = {
      fechaActual,
      nombre,
      celular,
      // correo,
      edad,
      identificacion,
      fechaNacimiento,
      // fechaCita,
      nombreUsuario,
      otroDiagnostico,
      observacionAdministrador,
      observacionDoctor,
      // ****************FOTOGRAFIA INTRA ORALES
      oclusionFrente: oclusionFrente ? 1 : 0,
      oclusionIzquierda: oclusionIzquierda ? 1 : 0,
      oclusionDerecha: oclusionDerecha ? 1 : 0,
      arcoSuperior: arcoSuperior ? 1 : 0,
      arcoInferior: arcoInferior ? 1 : 0,
      overJet: overJet ? 1 : 0,
      overBite: overBite ? 1 : 0,
      // ****************DIAGNOSTICOS
      steiner: steiner ? 1 : 0,
      mcnamara: mcnamara ? 1 : 0,
      ricketts: ricketts ? 1 : 0,
      grummons: grummons ? 1 : 0,
      // otroDiagnostico: otroDiagnostico ? 1 : 0,
      // **************** RX Intra Orales
      izqArriba1: izqArriba1 ? 1 : 0,
      izqArriba2: izqArriba2 ? 1 : 0,
      izqArriba3: izqArriba3 ? 1 : 0,
      izqArriba4: izqArriba4 ? 1 : 0,
      izqArriba5: izqArriba5 ? 1 : 0,
      izqArriba6: izqArriba6 ? 1 : 0,
      izqArriba7: izqArriba7 ? 1 : 0,
      izqArriba8: izqArriba8 ? 1 : 0,
      izqAbajo1: izqAbajo1 ? 1 : 0,
      izqAbajo2: izqAbajo2 ? 1 : 0,
      izqAbajo3: izqAbajo3 ? 1 : 0,
      izqAbajo4: izqAbajo4 ? 1 : 0,
      izqAbajo5: izqAbajo5 ? 1 : 0,
      izqAbajo6: izqAbajo6 ? 1 : 0,
      izqAbajo7: izqAbajo7 ? 1 : 0,
      izqAbajo8: izqAbajo8 ? 1 : 0,
      derArriba1: derArriba1 ? 1 : 0,
      derArriba2: derArriba2 ? 1 : 0,
      derArriba3: derArriba3 ? 1 : 0,
      derArriba4: derArriba4 ? 1 : 0,
      derArriba5: derArriba5 ? 1 : 0,
      derArriba6: derArriba6 ? 1 : 0,
      derArriba7: derArriba7 ? 1 : 0,
      derArriba8: derArriba8 ? 1 : 0,
      derAbajo1: derAbajo1 ? 1 : 0,
      derAbajo2: derAbajo2 ? 1 : 0,
      derAbajo3: derAbajo3 ? 1 : 0,
      derAbajo4: derAbajo4 ? 1 : 0,
      derAbajo5: derAbajo5 ? 1 : 0,
      derAbajo6: derAbajo6 ? 1 : 0,
      derAbajo7: derAbajo7 ? 1 : 0,
      derAbajo8: derAbajo8 ? 1 : 0,
      periComp: periComp ? 1 : 0,
      parcial: parcial ? 1 : 0,
      milimetrada: milimetrada ? 1 : 0,
      compCoronales: compCoronales ? 1 : 0,
      coronalIzq: coronalIzq ? 1 : 0,
      coronalDer: coronalDer ? 1 : 0,
      oclusalSup: oclusalSup ? 1 : 0,
      oclusalInf: oclusalInf ? 1 : 0,
      intraOralesVirtual: intraOralesVirtual ? 1 : 0,
      intraOralesImpreso: intraOralesImpreso ? 1 : 0,
      // **************** Tomografia 3D
      tomoIzqSup1: tomoIzqSup1 ? 1 : 0,
      tomoIzqSup2: tomoIzqSup2 ? 1 : 0,
      tomoIzqSup3: tomoIzqSup3 ? 1 : 0,
      tomoIzqSup4: tomoIzqSup4 ? 1 : 0,
      tomoIzqSup5: tomoIzqSup5 ? 1 : 0,
      tomoIzqSup6: tomoIzqSup6 ? 1 : 0,
      tomoIzqSup7: tomoIzqSup7 ? 1 : 0,
      tomoIzqSup8: tomoIzqSup8 ? 1 : 0,
      tomoIzqInf1: tomoIzqInf1 ? 1 : 0,
      tomoIzqInf2: tomoIzqInf2 ? 1 : 0,
      tomoIzqInf3: tomoIzqInf3 ? 1 : 0,
      tomoIzqInf4: tomoIzqInf4 ? 1 : 0,
      tomoIzqInf5: tomoIzqInf5 ? 1 : 0,
      tomoIzqInf6: tomoIzqInf6 ? 1 : 0,
      tomoIzqInf7: tomoIzqInf7 ? 1 : 0,
      tomoIzqInf8: tomoIzqInf8 ? 1 : 0,
      tomoDerSup1: tomoDerSup1 ? 1 : 0,
      tomoDerSup2: tomoDerSup2 ? 1 : 0,
      tomoDerSup3: tomoDerSup3 ? 1 : 0,
      tomoDerSup4: tomoDerSup4 ? 1 : 0,
      tomoDerSup5: tomoDerSup5 ? 1 : 0,
      tomoDerSup6: tomoDerSup6 ? 1 : 0,
      tomoDerSup7: tomoDerSup7 ? 1 : 0,
      tomoDerSup8: tomoDerSup8 ? 1 : 0,
      tomoDerInf1: tomoDerInf1 ? 1 : 0,
      tomoDerInf2: tomoDerInf2 ? 1 : 0,
      tomoDerInf3: tomoDerInf3 ? 1 : 0,
      tomoDerInf4: tomoDerInf4 ? 1 : 0,
      tomoDerInf5: tomoDerInf5 ? 1 : 0,
      tomoDerInf6: tomoDerInf6 ? 1 : 0,
      tomoDerInf7: tomoDerInf7 ? 1 : 0,
      tomoDerInf8: tomoDerInf8 ? 1 : 0,
      zonaDiente: zonaDiente ? 1 : 0,
      maxiSuperior: maxiSuperior ? 1 : 0,
      maxiInferior: maxiInferior ? 1 : 0,
      maxiSupInf: maxiSupInf ? 1 : 0,
      caraCompleta: caraCompleta ? 1 : 0,
      scanCara: scanCara ? 1 : 0,
      tomoATM: tomoATM ? 1 : 0,
      ramasMandibular: ramasMandibular ? 1 : 0,
      senosParanasales: senosParanasales ? 1 : 0,
      narizProFase: narizProFase ? 1 : 0,
      viasAereas: viasAereas ? 1 : 0,
      vertebras: vertebras ? 1 : 0,
      medidasImplantes: medidasImplantes ? 1 : 0,
      // **************** RX Extra Orales
      panoramica: panoramica ? 1 : 0,
      perfil: perfil ? 1 : 0,
      anteroPosterior: anteroPosterior ? 1 : 0,
      posteroAnterior: posteroAnterior ? 1 : 0,
      carpograma: carpograma ? 1 : 0,
      submentonVertex: submentonVertex ? 1 : 0,
      proyeccionWaters: proyeccionWaters ? 1 : 0,
      atm: atm ? 1 : 0,
      senosMaxilares: senosMaxilares ? 1 : 0,
      extraOralesVirtual: extraOralesVirtual ? 1 : 0,
      extraOralesImpreso: extraOralesImpreso ? 1 : 0,
      // **************** Fotos Extra Orales
      frente: frente ? 1 : 0,
      sonrisa: sonrisa ? 1 : 0,
      perfilIzquierdo: perfilIzquierdo ? 1 : 0,
      perfilDerecho: perfilDerecho ? 1 : 0,
      tresCuartosDerecha: tresCuartosDerecha ? 1 : 0,
      tresCuartosIzquierda: tresCuartosIzquierda ? 1 : 0,
      baseNariz: baseNariz ? 1 : 0,
      basal: basal ? 1 : 0,
      tamanoNaturalUnoUno: tamanoNaturalUnoUno ? 1 : 0,
      // **************** Fondo
      negro: negro ? 1 : 0,
      blanco: blanco ? 1 : 0,
      azul: azul ? 1 : 0,
      // **************** Modelos
      deEstudio: deEstudio ? 1 : 0,
      deTrabajo: deTrabajo ? 1 : 0,
      // **************** Paquetes Diagnosticos
      ortopedia: ortopedia ? 1 : 0,
      basicoOrtodoncia: basicoOrtodoncia ? 1 : 0,
      basicoOrtodonciaScanner: basicoOrtodonciaScanner ? 1 : 0,
      plusOrtodoncia: plusOrtodoncia ? 1 : 0,
      plusOrtodonciaJuegoPeriapical: plusOrtodonciaJuegoPeriapical ? 1 : 0,
      periodoncia: periodoncia ? 1 : 0,
      disenoSonrisa: disenoSonrisa ? 1 : 0,
      cirujiaMaxilofacial: cirujiaMaxilofacial ? 1 : 0,
      rehabilitacion: rehabilitacion ? 1 : 0,
      // **************** Escáner Intra Oral
      escanerIntraoralADomicilio: escanerIntraoralADomicilio ? 1 : 0,
      digitalizacionDeModelos: digitalizacionDeModelos ? 1 : 0,
      impresionDeModelos: impresionDeModelos ? 1 : 0,
      // **************** Guías Quirurgicas
      guiasEscaner: guiasEscaner ? 1 : 0,
      guiasDiseño: guiasDiseño ? 1 : 0,
      guiasImpresion: guiasImpresion ? 1 : 0,
      guiasTomografiaGuia: guiasTomografiaGuia ? 1 : 0,
      // **************** Modelos Digitales
      impresion3D: impresion3D ? 1 : 0,
      modeloSuperior: modeloSuperior ? 1 : 0,
      modeloInferior: modeloInferior ? 1 : 0,
      // **************** Forma de Entrega Tomografías
      soloCD: soloCD ? 1 : 0,
      soloAcetato: soloAcetato ? 1 : 0,
      cdPapelFotografico: cdPapelFotografico ? 1 : 0,
      usbPapelFotografico: usbPapelFotografico ? 1 : 0,
      soloUSB: soloUSB ? 1 : 0,
      cdAcetato: cdAcetato ? 1 : 0,
      soloPapelFotografico: soloPapelFotografico ? 1 : 0,
      usbAcetato: usbAcetato ? 1 : 0,
      entregaLectura: entregaLectura ? 1 : 0,
    };

    // console.log("Datos a enviar:", datosEnviar); // Verificar los datos

    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas agregar este paciente?",
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: "#3085d6",
      // cancelButtonColor: "#d33",
      confirmButtonText: "Sí, agregar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(api + "?insertar=1", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datosEnviar),
        })
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            // console.log(datosRespuesta);
            Swal.fire(
              "¡Agregado!",
              "El paciente ha sido agregado exitosamente.",
              "success"
            ).then(() => {
              this.props.history.push("/");
            });
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "Hubo un problema al agregar el paciente.",
              "error"
            );
          });
      }
    });
  };

  confirmarCancelar = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Perderás los datos ingresados",
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: "#3085d6",
      // cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cancelar",
      cancelButtonText: "No, continuar editando",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.history.push("/");
      }
    });
  };

  render() {
    const {
      fechaActual,
      nombre,
      celular,
      // correo,
      edad,
      identificacion,
      fechaNacimiento,
      // fechaCita,
      nombreUsuario,
      tipoUsuario,
      // ****************FOTOGRAFIA INTRA ORALES
      oclusionFrente,
      oclusionIzquierda,
      oclusionDerecha,
      arcoSuperior,
      arcoInferior,
      overJet,
      overBite,
      // ****************DIAGNOSTICOS
      steiner,
      mcnamara,
      ricketts,
      grummons,
      otroDiagnostico,
      observacionAdministrador,
      observacionDoctor,
      // **************** RX Intra Orales
      izqArriba1,
      izqArriba2,
      izqArriba3,
      izqArriba4,
      izqArriba5,
      izqArriba6,
      izqArriba7,
      izqArriba8,
      izqAbajo1,
      izqAbajo2,
      izqAbajo3,
      izqAbajo4,
      izqAbajo5,
      izqAbajo6,
      izqAbajo7,
      izqAbajo8,
      derArriba1,
      derArriba2,
      derArriba3,
      derArriba4,
      derArriba5,
      derArriba6,
      derArriba7,
      derArriba8,
      derAbajo1,
      derAbajo2,
      derAbajo3,
      derAbajo4,
      derAbajo5,
      derAbajo6,
      derAbajo7,
      derAbajo8,
      periComp,
      parcial,
      milimetrada,
      compCoronales,
      coronalIzq,
      coronalDer,
      oclusalSup,
      oclusalInf,
      intraOralesVirtual,
      intraOralesImpreso,
      // **************** Tomografia 3D
      tomoIzqSup1,
      tomoIzqSup2,
      tomoIzqSup3,
      tomoIzqSup4,
      tomoIzqSup5,
      tomoIzqSup6,
      tomoIzqSup7,
      tomoIzqSup8,
      tomoIzqInf1,
      tomoIzqInf2,
      tomoIzqInf3,
      tomoIzqInf4,
      tomoIzqInf5,
      tomoIzqInf6,
      tomoIzqInf7,
      tomoIzqInf8,
      tomoDerSup1,
      tomoDerSup2,
      tomoDerSup3,
      tomoDerSup4,
      tomoDerSup5,
      tomoDerSup6,
      tomoDerSup7,
      tomoDerSup8,
      tomoDerInf1,
      tomoDerInf2,
      tomoDerInf3,
      tomoDerInf4,
      tomoDerInf5,
      tomoDerInf6,
      tomoDerInf7,
      tomoDerInf8,
      zonaDiente,
      maxiSuperior,
      maxiInferior,
      maxiSupInf,
      caraCompleta,
      scanCara,
      tomoATM,
      ramasMandibular,
      senosParanasales,
      narizProFase,
      viasAereas,
      vertebras,
      medidasImplantes,
      // **************** RX Extra Orales
      panoramica,
      perfil,
      anteroPosterior,
      posteroAnterior,
      carpograma,
      submentonVertex,
      proyeccionWaters,
      atm,
      senosMaxilares,
      extraOralesVirtual,
      extraOralesImpreso,
      // **************** Fotos Extra Orales
      frente,
      sonrisa,
      perfilIzquierdo,
      perfilDerecho,
      tresCuartosDerecha,
      tresCuartosIzquierda,
      baseNariz,
      basal,
      tamanoNaturalUnoUno,
      // **************** Fondo
      negro,
      blanco,
      azul,
      // **************** Modelos
      deEstudio,
      deTrabajo,
      // **************** Paquetes Diagnosticos
      ortopedia,
      basicoOrtodoncia,
      basicoOrtodonciaScanner,
      plusOrtodoncia,
      plusOrtodonciaJuegoPeriapical,
      periodoncia,
      disenoSonrisa,
      cirujiaMaxilofacial,
      rehabilitacion,
      // **************** Escáner Intra Oral
      escanerIntraoralADomicilio,
      digitalizacionDeModelos,
      impresionDeModelos,
      // **************** Guías Quirurgicas
      guiasEscaner,
      guiasDiseño,
      guiasImpresion,
      guiasTomografiaGuia,
      // **************** Modelos Digitales
      impresion3D,
      modeloSuperior,
      modeloInferior,
      // **************** Forma de Entrega Tomografías
      soloCD,
      soloAcetato,
      cdPapelFotografico,
      usbPapelFotografico,
      soloUSB,
      cdAcetato,
      soloPapelFotografico,
      usbAcetato,
      entregaLectura,
    } = this.state;
    return (
      <div className="card bg-body rounded poppins-regular">
        <div className="card-header text-white fw-bold d-flex justify-content-between bg-dark">
          <div className="card-header fw-bold border-bottom-0 h4 pt-3 fondoNuevoPaciente">
            NUEVO PACIENTE
          </div>
          <div className="card-header border-bottom-0 d-flex align-items-center">
            <label className="me-2 poppins-light " htmlFor="fechaActual">
              Fecha actual:
            </label>
            <span>{fechaActual}</span> {/* Muestra la fecha actual */}
          </div>
        </div>
        <div className="card-body border border-success ">
          <form onSubmit={this.enviarDatos}>
            <div>
              <h3 className="text-center bg-success text-white">
                Información Personal
              </h3>
              <div className="row mb-3">
                <div className="form-group col-md-5">
                  <label htmlFor="nombre">Nombre y Apellido</label>
                  <input
                    type="text"
                    name="nombre"
                    onChange={this.cambioValor}
                    value={nombre}
                    id="nombre"
                    className="form-control italic-placeholder"
                    placeholder="Ingrese el nombre completo"
                    required
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="identificacion">Identificación</label>
                  <input
                    type="text"
                    name="identificacion"
                    onChange={this.cambioValor}
                    value={identificacion}
                    id="identificacion"
                    className="form-control italic-placeholder"
                    placeholder="CC/CE. No."
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="celular">Celular</label>
                  <input
                    type="text"
                    name="celular"
                    onChange={this.cambioValor}
                    value={celular}
                    id="celular"
                    className="form-control italic-placeholder"
                    placeholder="301-123-4567"
                  />
                </div>
              </div>

              <div className="row mb-3 ">
                <div className="form-group col-md-3">
                  <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
                  <input
                    type="date"
                    name="fechaNacimiento"
                    onChange={this.cambioValor}
                    value={fechaNacimiento}
                    id="fechaNacimiento"
                    className="form-control italic-placeholder"
                    placeholder="Fecha de Nacimiento"
                  />
                </div>

                <div className="form-group col-md-2">
                  <label htmlFor="edad">Edad</label>
                  <input
                    type="number"
                    name="edad"
                    onChange={this.cambioValor}
                    value={edad}
                    id="edad"
                    className="form-control italic-placeholder"
                    placeholder="Ingrese la edad"
                    min="0"
                    max="100"
                  />
                </div>

                {/* ****el dcotor aun no esta agregado a la base de datos***** */}
                <div className="form-group col-md-4">
                  <label htmlFor="nombreUsuario">Doctor</label>
                  <input
                    type="text"
                    name="nombreUsuario"
                    onChange={this.cambioValor}
                    value={nombreUsuario.toUpperCase()}
                    id="nombreUsuario"
                    className="form-control italic-placeholder"
                    placeholder="nombre del doctor"
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* primera seccion */}
            <div className="form-group d-flex">
              <div className="form-group col-4 border border-success">
                <h3 className="text-center bg-success text-white">
                  RX Intra Orales
                </h3>
                <div className="form-group d-flex col-12">
                  <div className="form-group col-1 text-center align-self-center pt-1">
                    <label>DER.</label>
                  </div>
                  {/* div de los chechbox de numeros */}
                  <div className="form-group col-10 d-flex justify-content-center">
                    {/* lado izquierdo */}
                    <div>
                      {/* lado izquierdo arriba*/}
                      <div className="d-flex border-bottom pb-1">
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba8"
                          >
                            8
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba8"
                            onChange={this.cambioValor}
                            checked={izqArriba8}
                            id="izqArriba8"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba7"
                          >
                            7
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba7"
                            onChange={this.cambioValor}
                            checked={izqArriba7}
                            id="izqArriba7"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba6"
                          >
                            6
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba6"
                            onChange={this.cambioValor}
                            checked={izqArriba6}
                            id="izqArriba6"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba5"
                          >
                            5
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba5"
                            onChange={this.cambioValor}
                            checked={izqArriba5}
                            id="izqArriba5"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba4"
                          >
                            4
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba4"
                            onChange={this.cambioValor}
                            checked={izqArriba4}
                            id="izqArriba4"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba3"
                          >
                            3
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba3"
                            onChange={this.cambioValor}
                            checked={izqArriba3}
                            id="izqArriba3"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="izqArriba2"
                          >
                            2
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba2"
                            onChange={this.cambioValor}
                            checked={izqArriba2}
                            id="izqArriba2"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqArriba1"
                          >
                            1
                          </label>
                          <input
                            type="checkbox"
                            name="izqArriba1"
                            onChange={this.cambioValor}
                            checked={izqArriba1}
                            id="izqArriba1"
                            className="form-check-input mx-auto"
                          />
                        </div>
                      </div>
                      {/* lado izquierdo abajo*/}
                      <div className="d-flex ">
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo8"
                            onChange={this.cambioValor}
                            checked={izqAbajo8}
                            id="izqAbajo8"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo8"
                          >
                            8
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo7"
                            onChange={this.cambioValor}
                            checked={izqAbajo7}
                            id="izqAbajo7"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo7"
                          >
                            7
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo6"
                            onChange={this.cambioValor}
                            checked={izqAbajo6}
                            id="izqAbajo6"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo6"
                          >
                            6
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo5"
                            onChange={this.cambioValor}
                            checked={izqAbajo5}
                            id="izqAbajo5"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo5"
                          >
                            5
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo4"
                            onChange={this.cambioValor}
                            checked={izqAbajo4}
                            id="izqAbajo4"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo4"
                          >
                            4
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo3"
                            onChange={this.cambioValor}
                            checked={izqAbajo3}
                            id="izqAbajo3"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo3"
                          >
                            3
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo2"
                            onChange={this.cambioValor}
                            checked={izqAbajo2}
                            id="izqAbajo2"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo2"
                          >
                            2
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="izqAbajo1"
                            onChange={this.cambioValor}
                            checked={izqAbajo1}
                            id="izqAbajo1"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="izqAbajo1"
                          >
                            1
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* lado derecho */}
                    <div className="border-start ms-1">
                      {/* lado derecho arriba */}
                      <div className="d-flex border-bottom pb-1">
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba1"
                          >
                            1
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba1"
                            onChange={this.cambioValor}
                            checked={derArriba1}
                            id="derArriba1"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba2"
                          >
                            2
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba2"
                            onChange={this.cambioValor}
                            checked={derArriba2}
                            id="derArriba2"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba3"
                          >
                            3
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba3"
                            onChange={this.cambioValor}
                            checked={derArriba3}
                            id="derArriba3"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba4"
                          >
                            4
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba4"
                            onChange={this.cambioValor}
                            checked={derArriba4}
                            id="derArriba4"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba5"
                          >
                            5
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba5"
                            onChange={this.cambioValor}
                            checked={derArriba5}
                            id="derArriba5"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba6"
                          >
                            6
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba6"
                            onChange={this.cambioValor}
                            checked={derArriba6}
                            id="derArriba6"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="derArriba7"
                          >
                            7
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba7"
                            onChange={this.cambioValor}
                            checked={derArriba7}
                            id="derArriba7"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block "
                            htmlFor="derArriba8"
                          >
                            8
                          </label>
                          <input
                            type="checkbox"
                            name="derArriba8"
                            onChange={this.cambioValor}
                            checked={derArriba8}
                            id="derArriba8"
                            className="form-check-input mx-auto"
                          />
                        </div>
                      </div>
                      {/* lado derecho abajo */}
                      <div className="d-flex ">
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo1"
                            onChange={this.cambioValor}
                            checked={derAbajo1}
                            id="derAbajo1"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo1"
                          >
                            1
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo2"
                            onChange={this.cambioValor}
                            checked={derAbajo2}
                            id="derAbajo2"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo2"
                          >
                            2
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo3"
                            onChange={this.cambioValor}
                            checked={derAbajo3}
                            id="derAbajo3"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo3"
                          >
                            3
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo4"
                            onChange={this.cambioValor}
                            checked={derAbajo4}
                            id="derAbajo4"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo4"
                          >
                            4
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo5"
                            onChange={this.cambioValor}
                            checked={derAbajo5}
                            id="derAbajo5"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo5"
                          >
                            5
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo6"
                            onChange={this.cambioValor}
                            checked={derAbajo6}
                            id="derAbajo6"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo6"
                          >
                            6
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo7"
                            onChange={this.cambioValor}
                            checked={derAbajo7}
                            id="derAbajo7"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo7"
                          >
                            7
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="derAbajo8"
                            onChange={this.cambioValor}
                            checked={derAbajo8}
                            id="derAbajo8"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="derAbajo8"
                          >
                            8
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-1 align-self-center pt-1">
                    <label>IZQ.</label>
                  </div>
                </div>
                <br />
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="periComp"
                        onChange={this.cambioValor}
                        checked={periComp}
                        id="periComp"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="periComp">
                        Periapical Completo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="parcial"
                        onChange={this.cambioValor}
                        checked={parcial}
                        id="parcial"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="parcial">
                        Parcial
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="milimetrada"
                        onChange={this.cambioValor}
                        checked={milimetrada}
                        id="milimetrada"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="milimetrada">
                        Milimetrada
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="compCoronales"
                        onChange={this.cambioValor}
                        checked={compCoronales}
                        id="compCoronales"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="compCoronales"
                      >
                        Completo con Coronales
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="coronalIzq"
                        onChange={this.cambioValor}
                        checked={coronalIzq}
                        id="coronalIzq"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="coronalIzq">
                        Coronal Izquierda
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="coronalDer"
                        onChange={this.cambioValor}
                        checked={coronalDer}
                        id="coronalDer"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="coronalDer">
                        Coronal Derecha
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="oclusalSup"
                        onChange={this.cambioValor}
                        checked={oclusalSup}
                        id="oclusalSup"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="oclusalSup">
                        Oclusal Superior
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="oclusalInf"
                        onChange={this.cambioValor}
                        checked={oclusalInf}
                        id="oclusalInf"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="oclusalInf">
                        Oclusal Inferior
                      </label>
                    </div>
                  </div>
                </div>
                {/* //// aqui */}
                <div className="form-group">
                  <div className="form-group">
                    <h3 className="text-center">
                      <span className="bg-success text-white ps-1 pe-1">
                        Forma de envío
                      </span>
                    </h3>
                    <div className="form-group d-flex">
                      <div className="form-check col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="intraOralesVirtual"
                            onChange={this.cambioValor}
                            checked={intraOralesVirtual}
                            id="intraOralesVirtual"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="intraOralesVirtual"
                          >
                            Virtual
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="intraOralesImpreso"
                            onChange={this.cambioValor}
                            checked={intraOralesImpreso}
                            id="intraOralesImpreso"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="intraOralesImpreso"
                          >
                            Impreso
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* hasta aqui */}
              </div>
              <div className="form-group col-4">
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">
                    Fotografia Intra Orales
                  </h3>
                  <div className="form-group d-flex">
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionFrente"
                          onChange={this.cambioValor}
                          checked={oclusionFrente}
                          id="oclusionFrente"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionFrente"
                        >
                          Oclusión Frente
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionIzquierda"
                          onChange={this.cambioValor}
                          checked={oclusionIzquierda}
                          id="oclusionIzquierda"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionIzquierda"
                        >
                          Oclusión Izquierda
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="oclusionDerecha"
                          onChange={this.cambioValor}
                          checked={oclusionDerecha}
                          id="oclusionDerecha"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oclusionDerecha"
                        >
                          Oclusión Derecha
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="arcoSuperior"
                          onChange={this.cambioValor}
                          checked={arcoSuperior}
                          id="arcoSuperior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="arcoSuperior"
                        >
                          Arco Superior
                        </label>
                      </div>
                    </div>
                    <div className="form-check col-6">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="arcoInferior"
                          onChange={this.cambioValor}
                          checked={arcoInferior}
                          id="arcoInferior"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="arcoInferior"
                        >
                          Arco Inferior
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="overJet"
                          onChange={this.cambioValor}
                          checked={overJet}
                          id="overJet"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="overJet">
                          Over-jet
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="overBite"
                          onChange={this.cambioValor}
                          checked={overBite}
                          id="overBite"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="overBite">
                          Over-bite
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">
                    Fotos Extra Orales
                  </h3>
                  <div className="form-group d-flex">
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="frente"
                          onChange={this.cambioValor}
                          checked={frente}
                          id="frente"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="frente">
                          Frente
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="sonrisa"
                          onChange={this.cambioValor}
                          checked={sonrisa}
                          id="sonrisa"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="sonrisa">
                          Sonrisa
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="perfilIzquierdo"
                          onChange={this.cambioValor}
                          checked={perfilIzquierdo}
                          id="perfilIzquierdo"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="perfilIzquierdo"
                        >
                          Perfil Izquierdo
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="perfilDerecho"
                          onChange={this.cambioValor}
                          checked={perfilDerecho}
                          id="perfilDerecho"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="perfilDerecho"
                        >
                          Perfil Derecho
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="tresCuartosDerecha"
                          onChange={this.cambioValor}
                          checked={tresCuartosDerecha}
                          id="tresCuartosDerecha"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tresCuartosDerecha"
                        >
                          3/4 Derecha
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="tresCuartosIzquierda"
                          onChange={this.cambioValor}
                          checked={tresCuartosIzquierda}
                          id="tresCuartosIzquierda"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tresCuartosIzquierda"
                        >
                          3/4 Izquierda
                        </label>
                      </div>
                    </div>
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="baseNariz"
                          onChange={this.cambioValor}
                          checked={baseNariz}
                          id="baseNariz"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="baseNariz">
                          Base Nariz
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="basal"
                          onChange={this.cambioValor}
                          checked={basal}
                          id="basal"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="basal">
                          Basal
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="tamanoNaturalUnoUno"
                          onChange={this.cambioValor}
                          checked={tamanoNaturalUnoUno}
                          id="tamanoNaturalUnoUno"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tamanoNaturalUnoUno"
                        >
                          Tamaño Natural 1-1
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-4 border border-danger">
                <h3 className="text-center bg-danger text-white">
                  Tomografía 3D
                </h3>
                <div className="form-group d-flex col-12">
                  <div className="form-group col-1 text-center align-self-center pt-1">
                    <label>DER.</label>
                  </div>
                  {/* div de los chechbox de numeros */}
                  <div className="form-group col-10 d-flex justify-content-center">
                    {/* lado izquierdo */}
                    <div>
                      {/* lado izquierdo arriba*/}
                      <div className="d-flex border-bottom pb-1">
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup8"
                          >
                            8
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup8"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup8}
                            id="tomoIzqSup8"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup7"
                          >
                            7
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup7"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup7}
                            id="tomoIzqSup7"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup6"
                          >
                            6
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup6"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup6}
                            id="tomoIzqSup6"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup5"
                          >
                            5
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup5"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup5}
                            id="tomoIzqSup5"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup4"
                          >
                            4
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup4"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup4}
                            id="tomoIzqSup4"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup3"
                          >
                            3
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup3"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup3}
                            id="tomoIzqSup3"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoIzqSup2"
                          >
                            2
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup2"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup2}
                            id="tomoIzqSup2"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqSup1"
                          >
                            1
                          </label>
                          <input
                            type="checkbox"
                            name="tomoIzqSup1"
                            onChange={this.cambioValor}
                            checked={tomoIzqSup1}
                            id="tomoIzqSup1"
                            className="form-check-input mx-auto"
                          />
                        </div>
                      </div>
                      {/* lado izquierdo abajo*/}
                      <div className="d-flex ">
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf8"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf8}
                            id="tomoIzqInf8"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf8"
                          >
                            8
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf7"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf7}
                            id="tomoIzqInf7"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf7"
                          >
                            7
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf6"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf6}
                            id="tomoIzqInf6"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf6"
                          >
                            6
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf5"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf5}
                            id="tomoIzqInf5"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf5"
                          >
                            5
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf4"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf4}
                            id="tomoIzqInf4"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf4"
                          >
                            4
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf3"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf3}
                            id="tomoIzqInf3"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf3"
                          >
                            3
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf2"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf2}
                            id="tomoIzqInf2"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf2"
                          >
                            2
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoIzqInf1"
                            onChange={this.cambioValor}
                            checked={tomoIzqInf1}
                            id="tomoIzqInf1"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoIzqInf1"
                          >
                            1
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* lado derecho */}
                    <div className="border-start ms-1">
                      {/* lado derecho arriba */}
                      <div className="d-flex border-bottom pb-1">
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup1"
                          >
                            1
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup1"
                            onChange={this.cambioValor}
                            checked={tomoDerSup1}
                            id="tomoDerSup1"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup2"
                          >
                            2
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup2"
                            onChange={this.cambioValor}
                            checked={tomoDerSup2}
                            id="tomoDerSup2"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup3"
                          >
                            3
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup3"
                            onChange={this.cambioValor}
                            checked={tomoDerSup3}
                            id="tomoDerSup3"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup4"
                          >
                            4
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup4"
                            onChange={this.cambioValor}
                            checked={tomoDerSup4}
                            id="tomoDerSup4"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup5"
                          >
                            5
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup5"
                            onChange={this.cambioValor}
                            checked={tomoDerSup5}
                            id="tomoDerSup5"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup6"
                          >
                            6
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup6"
                            onChange={this.cambioValor}
                            checked={tomoDerSup6}
                            id="tomoDerSup6"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block"
                            htmlFor="tomoDerSup7"
                          >
                            7
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup7"
                            onChange={this.cambioValor}
                            checked={tomoDerSup7}
                            id="tomoDerSup7"
                            className="form-check-input mx-auto"
                          />
                        </div>
                        <div className="form-check text-center ps-1">
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerSup8"
                          >
                            8
                          </label>
                          <input
                            type="checkbox"
                            name="tomoDerSup8"
                            onChange={this.cambioValor}
                            checked={tomoDerSup8}
                            id="tomoDerSup8"
                            className="form-check-input mx-auto"
                          />
                        </div>
                      </div>
                      {/* lado derecho abajo */}
                      <div className="d-flex ">
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf1"
                            onChange={this.cambioValor}
                            checked={tomoDerInf1}
                            id="tomoDerInf1"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf1"
                          >
                            1
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf2"
                            onChange={this.cambioValor}
                            checked={tomoDerInf2}
                            id=" tomoDerInf2"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf2"
                          >
                            2
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf3"
                            onChange={this.cambioValor}
                            checked={tomoDerInf3}
                            id="tomoDerInf3"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf3"
                          >
                            3
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf4"
                            onChange={this.cambioValor}
                            checked={tomoDerInf4}
                            id="tomoDerInf4"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf4"
                          >
                            4
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf5"
                            onChange={this.cambioValor}
                            checked={tomoDerInf5}
                            id="tomoDerInf5"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf5"
                          >
                            5
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf6"
                            onChange={this.cambioValor}
                            checked={tomoDerInf6}
                            id="tomoDerInf6"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf6"
                          >
                            6
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf7"
                            onChange={this.cambioValor}
                            checked={tomoDerInf7}
                            id="tomoDerInf7"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf7"
                          >
                            7
                          </label>
                        </div>
                        <div className="form-check text-center ps-1">
                          <input
                            type="checkbox"
                            name="tomoDerInf8"
                            onChange={this.cambioValor}
                            checked={tomoDerInf8}
                            id="tomoDerInf8"
                            className="form-check-input mx-auto"
                          />
                          <label
                            className="form-check-label d-block "
                            htmlFor="tomoDerInf8"
                          >
                            8
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-1 align-self-center pt-1">
                    <label>IZQ.</label>
                  </div>
                </div>
                <br />
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="zonaDiente"
                        onChange={this.cambioValor}
                        checked={zonaDiente}
                        id="zonaDiente"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="zonaDiente">
                        Zona Diente(s)
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="narizProFase"
                        onChange={this.cambioValor}
                        checked={narizProFase}
                        id="narizProFase"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="narizProFase"
                      >
                        Zona Diente alta resolución (0.75)
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="medidasImplantes"
                        onChange={this.cambioValor}
                        checked={medidasImplantes}
                        id="medidasImplantes"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="medidasImplantes"
                      >
                        Cuadrante
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="maxiSuperior"
                        onChange={this.cambioValor}
                        checked={maxiSuperior}
                        id="maxiSuperior"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="maxiSuperior"
                      >
                        Maxilar Superior
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="maxiInferior"
                        onChange={this.cambioValor}
                        checked={maxiInferior}
                        id="maxiInferior"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="maxiInferior"
                      >
                        Maxilar Inferior
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="maxiSupInf"
                        onChange={this.cambioValor}
                        checked={maxiSupInf}
                        id="maxiSupInf"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="maxiSupInf">
                        Maxilar Superior e Inferior
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="caraCompleta"
                        onChange={this.cambioValor}
                        checked={caraCompleta}
                        id="caraCompleta"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="caraCompleta"
                      >
                        Cara Completa
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="scanCara"
                        onChange={this.cambioValor}
                        checked={scanCara}
                        id="scanCara"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="scanCara">
                        Scan Cara
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="tomoATM"
                        onChange={this.cambioValor}
                        checked={tomoATM}
                        id="tomoATM"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="tomoATM">
                        ATM
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="ramasMandibular"
                        onChange={this.cambioValor}
                        checked={ramasMandibular}
                        id="ramasMandibular"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ramasMandibular"
                      >
                        Ramas Mandibulares
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="senosParanasales"
                        onChange={this.cambioValor}
                        checked={senosParanasales}
                        id="senosParanasales"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="senosParanasales"
                      >
                        Senos Paranasales
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="viasAereas"
                        onChange={this.cambioValor}
                        checked={viasAereas}
                        id="viasAereas"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="viasAereas">
                        Vías Aéreas
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="vertebras"
                        onChange={this.cambioValor}
                        checked={vertebras}
                        id="vertebras"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="vertebras">
                        Vertebras
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SEGUNDA SECCION */}
            <div className="form-group d-flex">
              <div className="form-group col-4 border border-success">
                <h3 className="text-center bg-success text-white">
                  RX Extra Orales
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="panoramica"
                        onChange={this.cambioValor}
                        checked={panoramica}
                        id="panoramica"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="panoramica">
                        Panorámica
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="perfil"
                        onChange={this.cambioValor}
                        checked={perfil}
                        id="perfil"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="perfil">
                        Perfil
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="anteroPosterior"
                        onChange={this.cambioValor}
                        checked={anteroPosterior}
                        id="anteroPosterior"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="anteroPosterior"
                      >
                        Antero Posterior
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="posteroAnterior"
                        onChange={this.cambioValor}
                        checked={posteroAnterior}
                        id="posteroAnterior"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="posteroAnterior"
                      >
                        Postero Anterior
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="carpograma"
                        onChange={this.cambioValor}
                        checked={carpograma}
                        id="carpograma"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="carpograma">
                        Carpograma
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="submentonVertex"
                        onChange={this.cambioValor}
                        checked={submentonVertex}
                        id="submentonVertex"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="submentonVertex"
                      >
                        Submenton Vertex
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="proyeccionWaters"
                        onChange={this.cambioValor}
                        checked={proyeccionWaters}
                        id="proyeccionWaters"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="proyeccionWaters"
                      >
                        Proyección Waters
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="atm"
                        onChange={this.cambioValor}
                        checked={atm}
                        id="atm"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="atm">
                        ATM
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="senosMaxilares"
                        onChange={this.cambioValor}
                        checked={senosMaxilares}
                        id="senosMaxilares"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="senosMaxilares"
                      >
                        Senos Maxilares
                      </label>
                    </div>
                  </div>
                </div>
                {/* //// aqui */}
                <div className="form-group">
                  <div className="form-group">
                    <h3 className="text-center">
                      <span className="bg-success text-white ps-1 pe-1">
                        Forma de envío
                      </span>
                    </h3>
                    <div className="form-group d-flex">
                      <div className="form-check col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="extraOralesVirtual"
                            onChange={this.cambioValor}
                            checked={extraOralesVirtual}
                            id="extraOralesVirtual"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="extraOralesVirtual"
                          >
                            Virtual
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="extraOralesImpreso"
                            onChange={this.cambioValor}
                            checked={extraOralesImpreso}
                            id="extraOralesImpreso"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="extraOralesImpreso"
                          >
                            Impreso
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* hasta aqui */}
              </div>
              <div className="form-group col-2">
                <h3 className="text-center bg-success text-white">
                  Diagnosticos
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="steiner"
                        onChange={this.cambioValor}
                        checked={steiner}
                        id="steiner"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="steiner">
                        Steiner
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="mcnamara"
                        onChange={this.cambioValor}
                        checked={mcnamara}
                        id="mcnamara"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="mcnamara">
                        Mcnamara
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="ricketts"
                        onChange={this.cambioValor}
                        checked={ricketts}
                        id="ricketts"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="ricketts">
                        Ricketts
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="grummons"
                        onChange={this.cambioValor}
                        checked={grummons}
                        id="grummons"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="grummons">
                        Grummons
                      </label>
                    </div>
                  </div>
                  {/* <div className="form-check">
                      <input
                        type="checkbox"
                        name="otroDiagnostico"
                        onChange={this.cambioValor}
                        checked={otroDiagnostico}
                        id="otroDiagnostico"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="otroDiagnostico"
                      >
                        Otro
                      </label>
                    </div> */}
                </div>
                <br />
                <div className="form-group d-flex me-3 ms-3">
                  <div className="input-group">
                    <span className="input-group-text d-flex align-items-center justify-content-center">
                      Otro
                    </span>
                    <textarea
                      className="form-control"
                      aria-label="With textarea"
                      name="otroDiagnostico"
                      value={otroDiagnostico}
                      onChange={this.cambioValor}
                      // disabled={otroDiagnostico}
                      id="otroDiagnostico"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="form-group col-2">
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">Fondo</h3>
                  <div className="form-group d-flex">
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="negro"
                          onChange={this.cambioValor}
                          checked={negro}
                          id="negro"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="negro">
                          Negro
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="blanco"
                          onChange={this.cambioValor}
                          checked={blanco}
                          id="blanco"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="blanco">
                          Blanco
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="azul"
                          onChange={this.cambioValor}
                          checked={azul}
                          id="azul"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="azul">
                          Azul
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h3 className="text-center bg-success text-white">Modelos</h3>
                  <div className="form-group d-flex">
                    <div className="form-check">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="deEstudio"
                          onChange={this.cambioValor}
                          checked={deEstudio}
                          id="deEstudio"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="deEstudio">
                          De Estudio
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="deTrabajo"
                          onChange={this.cambioValor}
                          checked={deTrabajo}
                          id="deTrabajo"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="deTrabajo">
                          De Trabajo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-4 border border-bottom-0 border-danger">
                <h3 className="text-center bg-danger text-white">
                  Forma de Entrega <br /> Tomografías
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="soloAcetato"
                        onChange={this.cambioValor}
                        checked={soloAcetato}
                        id="soloAcetato"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="soloAcetato">
                        Solo Acetato
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="soloUSB"
                        onChange={this.cambioValor}
                        checked={soloUSB}
                        id="soloUSB"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="soloUSB">
                        Solo USB
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="soloCD"
                        onChange={this.cambioValor}
                        checked={soloCD}
                        id="soloCD"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="soloCD">
                        Solo VIRTUAL
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="cdPapelFotografico"
                        onChange={this.cambioValor}
                        checked={cdPapelFotografico}
                        id="cdPapelFotografico"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cdPapelFotografico"
                      >
                        DICOM
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="soloPapelFotografico"
                        onChange={this.cambioValor}
                        checked={soloPapelFotografico}
                        id="soloPapelFotografico"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="soloPapelFotografico"
                      >
                        Solo Papel Fotográfico
                      </label>
                    </div>
                  </div>
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="cdAcetato"
                        onChange={this.cambioValor}
                        checked={cdAcetato}
                        id="cdAcetato"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="cdAcetato">
                        Solo Plantillas al Correo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="usbAcetato"
                        onChange={this.cambioValor}
                        checked={usbAcetato}
                        id="usbAcetato"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="usbAcetato">
                        USB + Acetato
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="usbPapelFotografico"
                        onChange={this.cambioValor}
                        checked={usbPapelFotografico}
                        id="usbPapelFotografico"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usbPapelFotografico"
                      >
                        USB + Papel Fotográfico
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="entregaLectura"
                        onChange={this.cambioValor}
                        checked={entregaLectura}
                        id="entregaLectura"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="entregaLectura"
                      >
                        LECTURA
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TERCERA SECCION */}
            <div className="form-group d-flex">
              <div className="form-group col-6">
                <h3 className="text-center bg-success text-white">
                  Paquetes <br />
                  Diagnósticos
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="ortopedia"
                        onChange={this.cambioValor}
                        checked={ortopedia}
                        id="ortopedia"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="ortopedia">
                        Ortopedia
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="basicoOrtodoncia"
                        onChange={this.cambioValor}
                        checked={basicoOrtodoncia}
                        id="basicoOrtodoncia"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basicoOrtodoncia"
                      >
                        Básico de Ortodoncia
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="basicoOrtodonciaScanner"
                        onChange={this.cambioValor}
                        checked={basicoOrtodonciaScanner}
                        id="basicoOrtodonciaScanner"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basicoOrtodonciaScanner"
                      >
                        Básico de Ortodoncia + Scanner
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="plusOrtodoncia"
                        onChange={this.cambioValor}
                        checked={plusOrtodoncia}
                        id="plusOrtodoncia"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="plusOrtodoncia"
                      >
                        Plus de Ortodoncia
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="plusOrtodonciaJuegoPeriapical"
                        onChange={this.cambioValor}
                        checked={plusOrtodonciaJuegoPeriapical}
                        id="plusOrtodonciaJuegoPeriapical"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="plusOrtodonciaJuegoPeriapical"
                      >
                        Plus de Ortod. + Juego Periapical
                      </label>
                    </div>
                  </div>
                  <div className="form-check col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="periodoncia"
                        onChange={this.cambioValor}
                        checked={periodoncia}
                        id="periodoncia"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="periodoncia">
                        Periodoncia
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="disenoSonrisa"
                        onChange={this.cambioValor}
                        checked={disenoSonrisa}
                        id="disenoSonrisa"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="disenoSonrisa"
                      >
                        Diseño Sonrisa
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="cirujiaMaxilofacial"
                        onChange={this.cambioValor}
                        checked={cirujiaMaxilofacial}
                        id="cirujiaMaxilofacial"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cirujiaMaxilofacial"
                      >
                        Cirujía Maxilofacial
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="rehabilitacion"
                        onChange={this.cambioValor}
                        checked={rehabilitacion}
                        id="rehabilitacion"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rehabilitacion"
                      >
                        Rehabilitación
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-2">
                <h3 className="text-center bg-danger text-white">
                  Escáner Intra <br /> Oral
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="escanerIntraoralADomicilio"
                        onChange={this.cambioValor}
                        checked={escanerIntraoralADomicilio}
                        id="escanerIntraoralADomicilio"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="escanerIntraoralADomicilio"
                      >
                        Escáner Intraoral
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="digitalizacionDeModelos"
                        onChange={this.cambioValor}
                        checked={digitalizacionDeModelos}
                        id="digitalizacionDeModelos"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="digitalizacionDeModelos"
                      >
                        Escáner a modelos
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="impresionDeModelos"
                        onChange={this.cambioValor}
                        checked={impresionDeModelos}
                        id="impresionDeModelos"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="impresionDeModelos"
                      >
                        Impresión de modelos
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-2">
                <h3 className="text-center bg-danger text-white">
                  Guías <br /> Quirurgicas
                </h3>
                <div className="form-group d-flex">
                  <div className="form-check">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="guiasEscaner"
                        onChange={this.cambioValor}
                        checked={guiasEscaner}
                        id="guiasEscaner"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="guiasEscaner"
                      >
                        Escáner
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="guiasDiseño"
                        onChange={this.cambioValor}
                        checked={guiasDiseño}
                        id="guiasDiseño"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="guiasDiseño">
                        Diseño
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="guiasImpresion"
                        onChange={this.cambioValor}
                        checked={guiasImpresion}
                        id="guiasImpresion"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="guiasImpresion"
                      >
                        Impresión
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="guiasTomografiaGuia"
                        onChange={this.cambioValor}
                        checked={guiasTomografiaGuia}
                        id="guiasTomografiaGuia"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="guiasTomografiaGuia"
                      >
                        Tomografía para Guía
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-2">
                <div className="form-group">
                  <div className="form-group">
                    <h3 className="text-center bg-danger text-white">
                      Modelos Digitales
                    </h3>
                    <div className="form-group">
                      <div className="form-check">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="impresion3D"
                            onChange={this.cambioValor}
                            checked={impresion3D}
                            id="impresion3D"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="impresion3D"
                          >
                            Impresión 3D
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="modeloSuperior"
                            onChange={this.cambioValor}
                            checked={modeloSuperior}
                            id="modeloSuperior"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="modeloSuperior"
                          >
                            Modelo Superior
                          </label>
                        </div>
                      </div>
                      <div className="form-check col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="modeloInferior"
                            onChange={this.cambioValor}
                            checked={modeloInferior}
                            id="modeloInferior"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="modeloInferior"
                          >
                            Modelo Inferior
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SECCION OBSERVACIONES */}
            <div className="form-group bg-dark text-white">
              <div className="text-center">
                <h3 className="text-white fw-bold pt-2">OBSERVACIONES</h3>
              </div>
              <div className="d-flex bg-secondary form-group">
                <div className="form-group col-6 border border-white rounded-top-3">
                  <h3 className="d-flex align-items-center justify-content-center pt-2">
                    Radiológico
                  </h3>
                  <div
                    className={`relative-container ${
                      tipoUsuario === "doctor" ? "disabled" : ""
                    }`}
                  >
                    <textarea
                      id="observacionAdministrador"
                      name="observacionAdministrador"
                      value={observacionAdministrador}
                      onChange={this.cambioValor}
                      className="form-control"
                      rows="3"
                      placeholder={
                        tipoUsuario === "doctor"
                          ? ""
                          : "Escribe aquí las observaciones del Radiológico"
                      }
                      disabled={tipoUsuario === "doctor"}
                    ></textarea>
                    {tipoUsuario === "doctor" && (
                      <div className="placeholder-text">
                        <i className="fas fa-lock"></i> Solo para Radiológico
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group col-6 border border-white rounded-top-3">
                  <h3 className="d-flex align-items-center justify-content-center pt-2">
                    Doctor
                  </h3>
                  <div
                    className={`relative-container ${
                      tipoUsuario === "administrador" ? "disabled" : ""
                    }`}
                  >
                    <textarea
                      id="observacionDoctor"
                      name="observacionDoctor"
                      value={observacionDoctor}
                      onChange={this.cambioValor}
                      className="form-control"
                      rows="3"
                      placeholder={
                        tipoUsuario === "administrador"
                          ? ""
                          : "Escribe aquí las observaciones del Doctor"
                      }
                      disabled={tipoUsuario === "administrador"}
                    ></textarea>
                    {tipoUsuario === "administrador" && (
                      <div className="placeholder-text">
                        <i className="fas fa-lock"></i> Solo para Doctores
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* SECCION BOTONES*/}
            <div className="d-flex justify-content-center  ">
              <button type="submit" className="btn btn-success me-2 col-6">
                Agregar
              </button>
              <button
                onClick={this.confirmarCancelar}
                className="btn btn-danger col-6"
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer text-muted bg-success"></div>
      </div>
    );
  }
}

export default withRouter(Crear);
