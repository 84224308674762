import React from "react";
import { Link } from "react-router-dom";
import apiDoctores from "../servicios/apiDoctores";
import apiPacientes from "../servicios/api";
import Swal from "sweetalert2";
import "../styles/style.css";

const capitalizarCadaPalabra = (texto) => {
  return texto
    .split(" ")
    .map(
      (palabra) =>
        palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
    )
    .join(" ");
};

const ITEMS_POR_PAGINA = 10;
const RANGO_PAGINAS = 10;

class ListaDoctores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      doctores: [],
      pacientes: [],
      textoBusqueda: "",
      textoBusquedaModalPacientes: "",
      paginaActual: 1,
      nombreUsuario: localStorage.getItem("nombre") || "",
      tipoUsuario: localStorage.getItem("tipo") || "",
      modalVisible: false,
      doctorSeleccionado: null,
    };
  }

  borrarRegistros = (id) => {
    const doctor = this.state.doctores.find((doc) => doc.id === id);

    if (!doctor) {
      Swal.fire("Error", "Doctor no encontrado", "error");
      return;
    }

    const numeroPacientes = this.contarPacientesPorNombre(doctor.nombre);

    Swal.fire({
      title: "¿Estás seguro?",
      text: `Este doctor tiene ${numeroPacientes} paciente(s) asociado(s). No podrás revertir esto.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(apiDoctores + "?borrar=" + id)
          .then((respuesta) => respuesta.json())
          .then(() => {
            this.cargarDatosDoctores();
            Swal.fire("¡Borrado!", "El registro ha sido borrado.", "success");
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "Hubo un problema al borrar el registro.",
              "error"
            );
          });
      }
    });
  };

  cargarDatosDoctores() {
    fetch(apiDoctores + "?listarDoctores")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ doctores: datosRespuesta });
        // console.log("Datos de doctores:", datosRespuesta);
      })
      .catch(console.log);
  }

  cargarDatosPacientes() {
    fetch(apiPacientes + "?listarPacientes")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ pacientes: datosRespuesta });
        // console.log("Datos de pacientes:", datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatosDoctores();
    this.cargarDatosPacientes();
    this.setState({ datosCargados: true });
  }

  manejarCambioBusqueda = (evento) => {
    this.setState({
      textoBusqueda: evento.target.value,
      paginaActual: 1,
    });
  };

  limpiarBusqueda = () => {
    this.setState({
      textoBusqueda: "",
      paginaActual: 1,
    });
  };

  obtenerDoctoresFiltrados() {
    const { doctores, textoBusqueda } = this.state;
    const textoBusquedaLower = textoBusqueda.toLowerCase();

    // Filtrar doctores por nombre o correo
    const doctoresFiltrados = doctores.filter(
      (doctor) =>
        doctor.nombre?.toLowerCase().includes(textoBusquedaLower) ||
        doctor.correo?.toLowerCase().includes(textoBusquedaLower)
    );

    // Ordenar doctores por la cantidad de pacientes (de mayor a menor)
    return doctoresFiltrados.sort((a, b) => {
      const pacientesA = this.contarPacientesPorNombre(a.nombre);
      const pacientesB = this.contarPacientesPorNombre(b.nombre);
      return pacientesB - pacientesA; // Orden descendente
    });
  }

  manejarCambioPagina = (pagina) => {
    this.setState({ paginaActual: pagina });
  };

  contarPacientesPorNombre(doctorNombre) {
    const { pacientes } = this.state;
    return pacientes.filter(
      (paciente) =>
        paciente.nombreUsuario &&
        paciente.nombreUsuario.toLowerCase() === doctorNombre.toLowerCase()
    ).length;
  }

  obtenerPacientesPorDoctor(doctorNombre) {
    const { pacientes } = this.state;

    // Filtra los pacientes por doctor y luego los ordena alfabéticamente por el nombre del paciente
    return pacientes
      .filter(
        (paciente) =>
          paciente.nombreUsuario &&
          paciente.nombreUsuario.toLowerCase() === doctorNombre.toLowerCase()
      )
      .sort((a, b) => {
        const nombreA = a.nombre.toLowerCase();
        const nombreB = b.nombre.toLowerCase();
        return nombreA < nombreB ? -1 : nombreA > nombreB ? 1 : 0;
      });
  }

  abrirModal = (doctor) => {
    this.setState({ modalVisible: true, doctorSeleccionado: doctor });
    document.body.classList.add("modal-open"); // Agregar clase para opacidad
  };

  cerrarModal = () => {
    this.setState({ modalVisible: false, doctorSeleccionado: null });
    document.body.classList.remove("modal-open"); // Eliminar clase para opacidad
  };

  render() {
    const {
      datosCargados,
      textoBusqueda,
      textoBusquedaModalPacientes,
      paginaActual,
      modalVisible,
      doctorSeleccionado,
    } = this.state;
    const doctoresFiltrados = this.obtenerDoctoresFiltrados();

    const indiceInicial = (paginaActual - 1) * ITEMS_POR_PAGINA;
    const doctoresPaginaActual = doctoresFiltrados.slice(
      indiceInicial,
      indiceInicial + ITEMS_POR_PAGINA
    );

    const totalPaginas = Math.ceil(doctoresFiltrados.length / ITEMS_POR_PAGINA);

    const paginaInicio = Math.max(
      1,
      paginaActual - Math.floor(RANGO_PAGINAS / 2)
    );
    const paginaFin = Math.min(totalPaginas, paginaInicio + RANGO_PAGINAS - 1);

    if (!datosCargados) {
      return <div>Cargando...</div>;
    }

    return (
      <div className="card poppins-regular">
        <div className="card-header bg-dark">
          <div className="d-flex justify-content-between align-items-center">
            <Link className="btn btn-light" to={"/agregar-doctor"}>
              <i className="fas fa-user-plus"></i> Agregar Nuevo Doctor
            </Link>

            <div className="position-relative w-50">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por nombre o correo"
                value={textoBusqueda}
                onChange={this.manejarCambioBusqueda}
              />
              {textoBusqueda && (
                <button
                  type="button"
                  className="btn btn-light position-absolute top-50 end-0 translate-middle-y me-2"
                  onClick={this.limpiarBusqueda}
                  style={{ border: "none", boxShadow: "none" }}
                >
                  <i className="fas fa-times text-danger"></i>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body text-center text-white bg-success">
          <h2 className="fw-bold pb-2">LISTA DE DOCTORES / USUARIOS</h2>
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>NOMBRE DOCTOR</th>
                <th>CORREO</th>
                <th>ROL</th>
                <th># PACIENTES</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {doctoresPaginaActual.map((doctor, index) => (
                <tr key={doctor.id}>
                  <td className="fw-bold">{indiceInicial + index + 1}</td>
                  <td className="text-truncate" style={{ maxWidth: "200px" }}>
                    {capitalizarCadaPalabra(doctor.nombre)}
                  </td>
                  <td className="text-truncate" style={{ maxWidth: "200px" }}>
                    {doctor.correo}
                  </td>
                  <td
                    className={`text-uppercase fw-bold ${
                      doctor.tipo === "administrador"
                        ? "bg-success text-white"
                        : "bg-secondary text-white"
                    }`}
                    style={{ maxWidth: "200px" }}
                  >
                    {doctor.tipo === "administrador"
                      ? "Radiológico"
                      : doctor.tipo}
                  </td>
                  <td className="fw-bold">
                    {this.contarPacientesPorNombre(doctor.nombre)}
                  </td>
                  <td>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.abrirModal(doctor)}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </button>
                      <Link
                        className="btn btn-warning"
                        to={`/editar-doctor/${doctor.id}`}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => this.borrarRegistros(doctor.id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer text-muted bg-dark">
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button
                  className={`page-link ${
                    paginaActual === 1 ? "text-success" : "text-success"
                  }`}
                  disabled={paginaActual === 1}
                  onClick={() => this.manejarCambioPagina(1)}
                >
                  Primera
                </button>
              </li>
              <li className="page-item">
                <button
                  className={`page-link ${
                    paginaActual === 1 ? "text-success" : "text-success"
                  }`}
                  disabled={paginaActual === 1}
                  onClick={() => this.manejarCambioPagina(paginaActual - 1)}
                >
                  Anterior
                </button>
              </li>
              {Array.from(
                { length: paginaFin - paginaInicio + 1 },
                (_, i) => i + paginaInicio
              ).map((pagina) => (
                <li
                  key={pagina}
                  className={`page-item ${
                    pagina === paginaActual ? "bg-success text-white" : ""
                  }`}
                >
                  <button
                    className={`page-link ${
                      pagina === paginaActual ? "text-white" : "text-success"
                    }`}
                    style={{
                      backgroundColor: pagina === paginaActual ? "#198754" : "",
                    }}
                    onClick={() => this.manejarCambioPagina(pagina)}
                  >
                    {pagina}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  className={`page-link ${
                    paginaActual === totalPaginas ||
                    paginaActual === totalPaginas - 1
                      ? "text-success"
                      : "text-success"
                  }`}
                  disabled={paginaActual === totalPaginas}
                  onClick={() => this.manejarCambioPagina(paginaActual + 1)}
                >
                  Siguiente
                </button>
              </li>
              <li className="page-item">
                <button
                  className={`page-link ${
                    paginaActual === totalPaginas ||
                    paginaActual === totalPaginas - 1
                      ? "text-success"
                      : "text-success"
                  }`}
                  disabled={paginaActual === totalPaginas}
                  onClick={() => this.manejarCambioPagina(totalPaginas)}
                >
                  Última
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* Modal */}
        {doctorSeleccionado && (
          <div
            className={`modal fade ${modalVisible ? "show" : ""}`}
            style={{ display: modalVisible ? "block" : "none" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content bg-light">
                <div className="modal-header bg-dark d-flex">
                  <h5
                    className="modal-title fs-6 text-white col-8"
                    id="modalTitle"
                  >
                    Detalles del Doctor: <br />
                    <span className="text-uppercase fw-bold fs-4">
                      {doctorSeleccionado.nombre}
                    </span>
                  </h5>
                  <div className="position-relative w-50">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Buscar paciente"
                      value={this.state.textoBusquedaModalPacientes}
                      onChange={(e) =>
                        this.setState({
                          textoBusquedaModalPacientes: e.target.value,
                        })
                      }
                    />
                    {textoBusquedaModalPacientes && (
                      <button
                        className="btn btn-light position-absolute top-50 end-0 translate-middle-y me-2"
                        onClick={() =>
                          this.setState({ textoBusquedaModalPacientes: "" })
                        }
                      >
                        <i className="fas fa-times text-danger"></i>
                      </button>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={this.cerrarModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body bg-success">
                  <div className="poppins-regular bg-success">
                    <h3 className="card-title text-uppercase text-center fw-bold text-white pb-1">
                      Pacientes Asociados
                    </h3>

                    <div className="position-relative w-50"></div>
                    <div className="table-responsive rounded-4">
                      <table className="table align-middle text-center">
                        <thead className="table-dark">
                          <tr className="align-middle">
                            <th className="fw-bold">N°</th>
                            <th className="text-nowrap text-uppercase">
                              Nombre y Apellido
                            </th>
                            <th className="text-uppercase">Identificación</th>
                            <th className="text-uppercase">Asistió</th>
                            <th className="text-uppercase">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.obtenerPacientesPorDoctor(
                            doctorSeleccionado.nombre
                          )
                            .filter((paciente) => {
                              const textoBusquedaModalPacientesLower =
                                this.state.textoBusquedaModalPacientes.toLowerCase();
                              return (
                                paciente.nombre
                                  ?.toLowerCase()
                                  .includes(textoBusquedaModalPacientesLower) ||
                                paciente.identificacion
                                  ?.toString()
                                  .includes(textoBusquedaModalPacientesLower)
                              );
                            })
                            .map((paciente, index) => (
                              <tr key={paciente.id}>
                                <td className="fw-bold">
                                  {indiceInicial + index + 1}
                                </td>
                                <td
                                  className="text-capitalize text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {paciente.nombre}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {paciente.identificacion}
                                </td>
                                <td>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={parseInt(paciente.asistio) === 1}
                                    onChange={(e) =>
                                      this.manejarCambioAsistio(
                                        paciente.id,
                                        e.target.checked
                                      )
                                    }
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Link
                                    className="btn btn-primary"
                                    to={`/ver/${paciente.id}`}
                                    onClick={this.cerrarModal} // Llama a cerrarModal al hacer clic
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success bg-primary"
                    onClick={this.cerrarModal}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListaDoctores;
